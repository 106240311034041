import PropTypes from 'prop-types';
import React from 'react';
import GoogleRating from '../../../assets/google-rating.inline.svg';
import Star from '../../../assets/rating-star.inline.svg';
import { TrustpilotReviews } from '../trustpilot-reviews';

const ExcellentReview = ({ reviews, pageName, rating }) => (
	<div className="max-w-m-screen mx-auto md:p-4 p-0">
		<div className="mx-auto grid grid-cols-1 md:grid-cols-5 overflow-clip">
			<div className=" mx-auto flex flex-col items-center justify-center col-span-1">
				<div className="text-3xl text-center py-2 mt-2">Excellent</div>
				<div className="text-3xl text-center py-0">Reviews</div>
				<div className="flex items-center mt-2">
					<div className="flex">
						{[...Array(5)].map((_, i) => (
							<Star
								className="h-4 w-4 ml-0.5 fill-current text-warning"
								keys={i}
							/>
						))}
					</div>
				</div>
				<div className="mt-2 text-sm">
					Rated{' '}
					<span className="font-bold">
						{rating.trustscore[0].trustscore}
					</span>{' '}
					Based on Google Reviews
				</div>
				<div className="mt-2 text-sm inline-flex items-center gap-2">
					<GoogleRating className="w-6 h-6" /> Google Reviews
				</div>
			</div>
			<div className="md:col-span-4 col-span-1 md:p-2 p-0">
				<TrustpilotReviews
					reviews={reviews}
					pageName={pageName}
					showRatings
				/>
			</div>
		</div>
	</div>
);
ExcellentReview.defaultProps = {
	reviews: [],
	rating: [],
	pageName: '',
};
ExcellentReview.propTypes = {
	pageName: PropTypes.string,
	rating: PropTypes.string,
	reviews: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			time: PropTypes.string,
			title: PropTypes.string,
			review: PropTypes.string,
			rating: PropTypes.number,
			isVerified: PropTypes.bool,
		})
	),
};
export default ExcellentReview;
