import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { clsx } from 'clsx';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation } from '@reach/router';
import { getLocale } from '../../../utils/locale';
import { useDrawer } from '../../../contexts/drawer-context';
import { Drawer } from '../drawer';
import { AdviceForm } from '../../organisms/advice-form';
import { AdviceFormCA } from '../../organisms/ca/forms/salesforce/advice';
import { SuccessForm } from '../../organisms/success-form';
import { SuccessFormCA } from '../../organisms/ca/forms/salesforce/success';
import { useScreenResizer } from '../../../contexts/screen-resize-context';

export const CallUs = ({
	className,
	altMobile,
	onClickProp,
	number,
	pageName,
	callFrom,
}) => {
	const data = useStaticQuery(graphql`
		query {
			datoCmsSiteSpecificSetting {
				phoneNumberCopy
			}
		}
	`);
	const location = useLocation();
	const locale = getLocale(location.pathname);
	const [fallBackNumber, setFallBackNumber] = useState('');
	const Component = 'a';
	useEffect(() => {
		if (!number) {
			if (locale === 'ca') {
				setFallBackNumber('1 (833) 247-3652');
			} else if (locale === 'ie') {
				setFallBackNumber('1800 719 247');
			} else {
				setFallBackNumber('0800 158 2313');
			}
		}
	}, [locale, number]);
	const { drawerRef: callDrawer, setDrawerRef } = useDrawer();
	const callAdviceFormRef = React.useRef(null);
	const anchorRef = React.useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');
	const { width } = useScreenResizer();
	const handlePhoneModal = (text) => {
		if (
			width > 500 &&
			locale !== 'ca' &&
			locale !== 'ie' &&
			callFrom === 'header'
		) {
			anchorRef.current.href = '#';
			setDrawerRef(callAdviceFormRef);
		}
		onClickProp({
			click_text: text,
		});
	};
	return (
		<>
			{pageName === 'safecheck' ? (
				<a
					ref={anchorRef}
					href={`${
						width > 500 && locale !== 'ca' && locale !== 'ie'
							? '#'
							: 'tel:0844 892 2486'
					}`}
					onClick={() => {
						handlePhoneModal('0844 892 2486');
					}}
					className={clsx(
						'text-lg',
						'sm:text-xl',
						'lg:text-2xl',
						'phone-number',
						'font-centra-medium',
						'text-brand-red-400',
						locale === 'ca' ? '' : 'ruler-click',
						'focus:outline-0 focus:shadow-focus',
						altMobile
							? 'inline-block leading-7 bg-white border-2 border-blue-400 rounded-lg pt-1 pb-2 px-4 lg:p-0 lg:bg-transparent lg:border-0 text-center lg:text-left'
							: 'flex flex-col leading-none',
						className
					)}
				>
					<span
						className={`${locale === 'ca' ? '' : 'ruler-landing'}`}
					>
						0844 892 2486
					</span>
					<span
						className={clsx(
							'text-xs text-black md:text-sm font-centra-book',
							altMobile ? 'flex items-center' : 'inline-block'
						)}
					>
						<span className="inline-block ml-1 mr-2 bg-blue-400 blue-dot shrink-0" />
						Book your audit now
					</span>
				</a>
			) : (
				<Component
					ref={anchorRef}
					href={`tel:${
						number?.replaceAll(' ', '') ||
						fallBackNumber?.replaceAll(' ', '')
					}`}
					onClick={() => {
						handlePhoneModal(number);
					}}
					className={clsx(
						'text-lg',
						'sm:text-xl',
						'lg:text-3xl',
						'phone-number',
						'font-centra-medium',
						'text-brand-red-400',
						locale === 'ca' ? '' : 'ruler-click',
						'focus:outline-0 focus:shadow-focus',
						altMobile
							? 'inline-block leading-7 bg-white border-2 border-blue-400 rounded-lg pt-1 pb-2 px-4 lg:p-0 lg:bg-transparent lg:border-0 text-center lg:text-left'
							: 'flex flex-col leading-none',
						className
					)}
				>
					<span className={`${locale === 'ca' ? '' : 'rulertel'}`}>
						{number || fallBackNumber}
					</span>
					<span
						className={clsx(
							'text-xl text-black md:text-lg font-centra-book',
							altMobile ? 'flex items-center' : 'inline-block'
						)}
					>
						<span className="inline-block ml-1 mr-2 bg-blue-400 blue-dot shrink-0" />
						{data.datoCmsSiteSpecificSetting.phoneNumberCopy}
					</span>
				</Component>
			)}
			{callDrawer && (
				<GoogleReCaptchaProvider
					reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
				>
					{locale !== 'ca' ? (
						<Drawer
							label="Book my free advice call"
							ref={callAdviceFormRef}
							open={callDrawer === callAdviceFormRef}
							hasClose
							className="!p-0"
							elevation={20}
						>
							{reference === '' ? (
								<AdviceForm
									setReference={setReference}
									setResponse={setResponse}
								/>
							) : (
								<SuccessForm
									reference={reference}
									response={response}
								/>
							)}
						</Drawer>
					) : (
						<Drawer
							label="Book my free advice call"
							ref={callAdviceFormRef}
							open={callDrawer === callAdviceFormRef}
							hasClose
							className="!p-0"
							elevation={20}
						>
							{reference === '' ? (
								<AdviceFormCA
									setReference={setReference}
									setResponse={setResponse}
								/>
							) : (
								<SuccessFormCA
									reference={reference}
									response={response}
								/>
							)}
						</Drawer>
					)}
				</GoogleReCaptchaProvider>
			)}
		</>
	);
};

CallUs.defaultProps = {
	className: '',
	altMobile: false,
	onClickProp: () => {},
	number: '',
	pageName: '',
	callFrom: '',
};

CallUs.propTypes = {
	className: PropTypes.string,
	altMobile: PropTypes.bool,
	onClickProp: PropTypes.func,
	number: PropTypes.string,
	pageName: PropTypes.string,
	callFrom: PropTypes.string,
};
