import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import footerImage from '../../../assets/ppc-awards-footer.png';
import { getLocale } from '../../../utils/locale';
import { CallUs } from '../../atoms/call-us';
import { HeadingLinksList } from '../../atoms/heading-links-list';
import { PBSLink } from '../../atoms/link';
import { Logo } from '../../atoms/logo';
import { SocialLinks } from '../../molecules/social-links';

import ExtraFooterMenu from '../../atoms/extra-footer-menu';
import * as footerStyles from './index.module.css';

const Footer = ({ siteTitle, footerContent, pageName }) => {
	const locale = getLocale(
		typeof window !== 'undefined' ? window.location.pathname : ''
	);

	const footerLogos = [
		{
			src: '/images/cyberessentials-certification.png',
			locale: ['en', 'ie'],
			alt: 'Cyber Essential Logo',
			width: '67',
			height: '80',
		},
		{
			src: '/images/bafe-logo.png',
			locale: ['en'],
			alt: 'BAFE SP205 Registration',
			width: '85',
			height: '80',
		},
		{
			src: '/images/iso-logo.png',
			locale: ['en', 'ie'],
			alt: 'ISO 27001 and 9001 accredited company.',
			width: '175',
			height: '80',
		},
		{
			src: '/images/top-track.png',
			locale: ['en', 'ie'],
			alt: 'The Sunday Times - Top Track 250.',
			width: '64',
			height: '80',
		},
		{
			src: '/images/glassdoor.png',
			locale: ['en', 'ie'],
			alt: 'Glassdoor 2018 Best Places To Work.',
			width: '53',
			height: '80',
		},
	];

	const [logoArray, setLogoArray] = useState([footerLogos]);
	useEffect(() => {
		setLogoArray(
			footerLogos.filter((logo) => logo.locale.includes(locale))
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	return (
		<footer className="font-centra-light">
			<div className="px-5 py-16 bg-white md:py-20 md:px-10">
				<div className="mx-auto lg:container">
					<div
						className={`grid gap-8 ${footerStyles.footerGrid} lg:flex lg:gap-0 lg:space-x-8 lg:justify-between`}
					>
						{/* <div className="flex mdx:justify-start max-mdx:col-span-full">
							<Logo width={126} height={36} title={siteTitle} />
						</div> */}
						<div className="flex mdx:justify-start max-mdx:col-span-full">
							{locale !== '' ? (
								<Logo
									width={126}
									height={36}
									title={siteTitle}
								/>
							) : (
								<ExtraFooterMenu
									className="max-smd:col-span-full"
									links={footerContent.extraFooterNavigation}
									heading="Locations"
									locale={locale}
								/>
							)}
						</div>
						{footerContent.footerNavigation.map((nav, index) =>
							index < 2 ? (
								<HeadingLinksList
									key={`footer-nav-${nav.title}`}
									heading={nav.title}
									links={nav.treeChildren}
									className="max-smd:col-span-full"
								/>
							) : null
						)}
						<span className="border-t-1 lg:border-l-1 lg:border-t-0 border-brand-pale-500 max-mdx:col-span-full" />
						<div className="max-smd:col-span-2">
							{locale === '' ? (
								<div className="mb-5">
									<Logo
										locale={locale}
										width={126}
										height={36}
										title={siteTitle}
									/>
								</div>
							) : null}
							<h2 className="text-2xl font-centra-medium">
								{footerContent.footerAddressTitle}
							</h2>
							<address className="mt-5 not-italic leading-7">
								{footerContent.footerAddress
									.split('\n')
									.map((line) => (
										<span
											key={`footer-address-${line}`}
											className="block"
										>
											{line}
										</span>
									))}
							</address>
							<CallUs
								className="mt-4"
								altMobile
								pageName={pageName}
							/>
						</div>
						<div className="mr-8 max-smd:col-span-1">
							<SocialLinks
								heading={footerContent.footerSocialTitle}
								headingClasses="text-2xl font-centra-medium text-center"
								className="gap-4 mt-4"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="p-5 text-sm text-white md:p-10 bg-brand-blue-400">
				<div className="mx-auto lg:container">
					<div className="flex flex-col gap-8 lg:gap-20 lg:flex-row">
						<div className="flex flex-wrap">
							<h2 className="mr-4 font-centra-medium">
								{footerContent.footerInternationalSitesCopy}
							</h2>
							<ul className="flex flex-wrap w-full mt-4 2xl:mt-0 2xl:w-auto">
								{footerContent.footerInternationalSites.map(
									(site) => (
										<li
											key={`footer-int-sites-${site.link}`}
											className="mb-4 mr-4"
										>
											<PBSLink
												to={site.link}
												variant="Unset"
											>
												{site.title}
											</PBSLink>
										</li>
									)
								)}
							</ul>
							<p className="w-full mt-4 leading-6">
								{footerContent.footerCopyright}
							</p>
						</div>
						<div className="flex flex-wrap items-center gap-4 shrink-0">
							{logoArray.map((logo) => (
								<img
									key={logo.src}
									src={logo.src}
									alt={logo.alt}
									width={`${logo.width}px`}
									height={`${logo.height}px`}
								/>
							))}

							{locale === 'ca' ? (
								<>
									<img
										src={footerImage}
										alt="Canadian Awards"
										className="max-h-[123px] mx-auto lg:ml-auto"
									/>
								</>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	siteTitle: 'Peninsula Homepage',
	pageName: '',
};

Footer.propTypes = {
	siteTitle: PropTypes.string,
	pageName: PropTypes.string,
	footerContent: PropTypes.shape({
		extraFooterNavigation: PropTypes.arrayOf(
			PropTypes.shape({
				headerTitle: PropTypes.string.isRequired,
				navLinks: PropTypes.arrayOf(
					PropTypes.shape({
						link: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired,
					})
				).isRequired,
			})
		).isRequired,
		footerNavigation: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				treeChildren: PropTypes.arrayOf(
					PropTypes.shape({
						link: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired,
					})
				).isRequired,
			})
		).isRequired,
		footerAddressTitle: PropTypes.string.isRequired,
		footerAddress: PropTypes.string.isRequired,
		footerSocialTitle: PropTypes.string.isRequired,
		footerInternationalSitesCopy: PropTypes.string.isRequired,
		footerInternationalSites: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				link: PropTypes.string.isRequired,
			})
		).isRequired,
		footerCopyright: PropTypes.string.isRequired,
	}).isRequired,
};

export { Footer };
