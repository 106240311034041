/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { DrawerProvider } from '../../../contexts/drawer-context';
import { MarketoProvider } from '../../../contexts/marketo-context';
import { ScreenResizerProvider } from '../../../contexts/screen-resize-context';

import { MasterLayout } from '../../master';

import { Footer } from '../../../components/organisms/footer-bdm';
import { Header } from '../../../components/organisms/header-bdm';

// import { Newsletter } from '../../components/molecules/newsletter';

const DefaultLayout = ({
	children,
	className,
	siteSettings,
	navigation,
	footerData,
	bdmNumber,
	bdmEmail,
	pageName,
}) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
			datoCmsSiteSpecificSetting {
				sideNavigationOpenCopy
				sideNavigationCloseCopy
				signInCopy
				footerAddressTitle
				footerSocialTitle
				footerInternationalSitesCopy
			}
		}
	`);

	const globalSiteSettings = {
		...siteSettings,
	};

	const headerContent = {
		...data.datoCmsSiteSpecificSetting,
		...navigation,
	};

	const footerContent = {
		...data.datoCmsSiteSpecificSetting,
		...footerData,
	};

	return (
		<ScreenResizerProvider>
			<DrawerProvider>
				<MarketoProvider>
					<MasterLayout>
						<Header
							siteTitle={data.site.siteMetadata.title}
							headerContent={headerContent}
							siteSettings={globalSiteSettings}
							pageName={pageName}
							bdmNumber={bdmNumber}
							bdmEmail={bdmEmail}
						/>
						<main id="main" className={className}>
							{children}
						</main>
						{/* <Newsletter /> */}
						<Footer
							footerContent={footerContent}
							siteTitle={data.site.siteMetadata.title}
						/>
					</MasterLayout>
				</MarketoProvider>
			</DrawerProvider>
		</ScreenResizerProvider>
	);
};

export const query = graphql`
	fragment siteSettings on DatoCmsSiteSpecificSetting {
		phoneNumber
	}
	fragment navItems on DatoCmsSiteSpecificSetting {
		phoneNumber
		primaryNavigation {
			title
			link
			treeChildren {
				title
				subText
				link
				icon
				position
				locale
			}
		}
		sideNavigation {
			title
			link
		}
	}
	fragment footerItems on DatoCmsSiteSpecificSetting {
		footerAddress
		footerNavigation {
			treeChildren {
				title
				link
				position
			}
			title
		}

		footerInternationalSites {
			title
			link
		}
		footerCopyright
	}
`;

DefaultLayout.defaultProps = {
	className: '',
	pageName: '',
	siteSettings: '',
	navigation: '',
	footerData: '',
	bdmNumber: '',
	bdmEmail: '',
};

DefaultLayout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	pageName: PropTypes.string,
	siteSettings: PropTypes.string,
	navigation: PropTypes.string,
	footerData: PropTypes.string,
	bdmNumber: PropTypes.number,
	bdmEmail: PropTypes.string,
};

export { DefaultLayout };
