// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { AdviceBanner } from '../components/organisms/advice-banner';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceStages } from '../components/organisms/advice-stages';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { FAQ } from '../components/organisms/faq';
import { IconCards } from '../components/organisms/icon-cards';
import { SuccessForm } from '../components/organisms/success-form';

import { MainHeader } from '../components/molecules/main-header';
import { OurSoftware } from '../components/molecules/our-software';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container';
import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { TwoColText } from '../components/molecules/two-col-text';

import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { ClientLogos } from '../components/atoms/client-logos';
import { Drawer } from '../components/atoms/drawer';
import { ReviewBanner } from '../components/atoms/review-banner';

import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';

import { HrefLang } from '../components/templates/hrefLang';
import { useDrawer } from '../contexts/drawer-context';

const SectorPage = ({ data, trustPilotBusinessUnitReviews, location }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsSectorPage.noIndex,
		noFollow: data.datoCmsSectorPage.noFollow,
		metaInformation: data.datoCmsSectorPage.metaInformation,
	};

	const adviceContainer = {
		...data.datoCmsSectorPage.adviceContainer,
		title: data.datoCmsSectorPage.adviceContainer.title.value.document
			.children,
	};

	const adviceBannerContent = {
		...data.datoCmsSectorPage.adviceBanner,
		title: data.datoCmsSectorPage.adviceBanner.title.value.document
			.children,
	};

	const textWithImageContainer = {
		...data.datoCmsSectorPage.textWithImageContainer,
		title: data.datoCmsSectorPage.textWithImageContainer.title.value
			.document.children,
		firstContent:
			data.datoCmsSectorPage.textWithImageContainer.firstContent,
		secondContent:
			data.datoCmsSectorPage.textWithImageContainer.secondContent,
	};

	const twoColumnText = {
		...data.datoCmsSectorPage.twoColumnText,
		title: data.datoCmsSectorPage.twoColumnText.title.value.document
			.children,
		content:
			data.datoCmsSectorPage.twoColumnText.content.value.document
				.children,
	};

	const headerCTAContent = {
		...data.datoCmsSectorPage.mainHeader,
		header: data.datoCmsSectorPage.mainHeader.header.value.document
			.children,
	};

	const faqContent = {
		title: data.datoCmsSectorPage.faqContainer.title,
		subText: data.datoCmsSectorPage.faqContainer.subText,
		questions: data.datoCmsSectorPage.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const uspBanner = data.datoCmsSectorPage.uspCtaCardsBanner;
	const uspBannerDisplayLinks =
		data.datoCmsSectorPage.uspCtaCardsBannerDisplayLinks;

	const uspContainer = {
		...data.datoCmsSectorPage.uspCardsContainer,
		title: data.datoCmsSectorPage.uspCardsContainer.title.value.document
			.children,
	};

	const reviewBannerContent = {
		title: data.datoCmsSectorPage.reviewBannerTitle,
		subText: data.datoCmsSectorPage.reviewBannerSubtext,
		reviewer: data.datoCmsSectorPage.reviewBannerReviewer,
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.sector,
			location,
			data: data.datoCmsSectorPage,
		}),
		image: getImageSeoSchema(
			data.datoCmsSectorPage?.mainHeader?.primaryImage
		),
	};

	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsSectorPage.internal.type,
		data.datoCmsSectorPage
	);
	const { locale } = data.datoCmsSectorPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsSectorPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			{data.datoCmsSectorPage.mainHeaderDisplayOnPage && (
				<MainHeader
					urlLocale={locale}
					drawerRef={adviceFormRef}
					{...headerCTAContent}
					rating={data.datoCmsTrustpilotRating}
				/>
			)}

			{data.datoCmsSectorPage.uspCtaCardsBannerDisplayOnPage && (
				<IconCards
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					showContentLinks={uspBannerDisplayLinks}
				/>
			)}
			{data.datoCmsSectorPage.textWithImageContainerDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImageContainer}
				/>
			)}
			{data.datoCmsSectorPage.adviceBannerDisplayOnPage && (
				<AdviceBanner
					drawerRef={adviceFormRef}
					adviceBannerContent={adviceBannerContent}
				/>
			)}
			{data.datoCmsSectorPage.twoColumnTextDisplayOnPage && (
				<TwoColText
					drawerRef={adviceFormRef}
					textColTextContent={twoColumnText}
				/>
			)}
			{data.datoCmsSectorPage.trustPilotBannerDisplayOnPage && (
				<TrustpilotReviews
					locale={locale}
					reviews={trustPilotBusinessUnitReviews}
				/>
			)}
			{data.datoCmsSectorPage.adviceContainerDisplayOnPage && (
				<AdviceStages
					drawerRef={adviceFormRef}
					adviceStagesContent={adviceContainer}
				/>
			)}
			{data.datoCmsSectorPage.clientLogosBannerDisplayOnPage && (
				<ClientLogos pathname={location.pathname} />
			)}
			{data.datoCmsSectorPage.uspCardsContainerDisplayOnPage && (
				<IconCards variant="Dark" iconCardsContent={uspContainer} />
			)}
			{data.datoCmsSectorPage.ourSoftwareDisplayOnPage && (
				<OurSoftware
					drawerRef={adviceFormRef}
					variant="Dark"
					ourSoftwareContent={data.datoCmsSectorPage.ourSoftware}
				/>
			)}
			{/* {locale !== 'en-CA' &&
				data.datoCmsSectorPage.feefoTestimonialsBannerDisplayOnPage && (
					<FeefoTestimonials locale={locale} />
				)} */}
			{data.datoCmsSectorPage.faqContainerDisplayOnPage && (
				<FAQ faqContent={faqContent} />
			)}
			{data.datoCmsSectorPage.reviewBannerDisplayOnPage && (
				<ReviewBanner reviewBannerContent={reviewBannerContent} />
			)}

			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query sectorPage($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		datoCmsTrustpilotRating(locale: { eq: $locale }) {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		datoCmsSectorPage(locale: { eq: $locale }, slug: { eq: $slug }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			internal {
				type
			}
			slug
			title
			adviceBannerDisplayOnPage
			adviceContainerDisplayOnPage
			clientLogosBannerDisplayOnPage
			feefoTestimonialsBannerDisplayOnPage
			mainHeaderDisplayOnPage
			ourSoftwareDisplayOnPage
			faqContainerDisplayOnPage
			trustPilotBannerDisplayOnPage
			twoColumnTextDisplayOnPage
			uspCtaCardsBannerDisplayOnPage
			uspCardsContainerDisplayOnPage
			uspCtaCardsBannerDisplayLinks
			textWithImageDisplayOnPage
			textWithImageContainerDisplayOnPage
			reviewBannerDisplayOnPage
			relatedPagesDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
			}
			adviceContainer {
				title {
					value
				}
				content
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
				advice {
					title
					content
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			twoColumnText {
				id
				title {
					value
				}
				content {
					value
				}
				primaryLinkCopy
				primaryLink
				primaryButtonOpenForm
				secondaryLinkCopy
				secondaryLink
			}
			ourSoftware {
				title
				content
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
				mobileImage {
					alt
					url
					gatsbyImageData(
						width: 230
						height: 277
						layout: CONSTRAINED
					)
				}
				desktopImage {
					alt
					url
					gatsbyImageData(
						width: 280
						height: 568
						layout: CONSTRAINED
					)
				}
				tickList {
					text
					subText
				}
			}
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			uspCtaCardsBanner {
				title
				content
				linkCopy
				link
				icon
			}
			textWithImageContainer {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
			}
			uspCardsContainer {
				title {
					value
				}
				subText
				uspCards {
					title
					content
					linkCopy
					link
					icon
				}
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			reviewBannerTitle
			reviewBannerSubtext
			reviewBannerReviewer
			reviewBannerTitle
			reviewBannerSubtext
			reviewBannerReviewer
			relatedPagesTitle
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;

const WrappedService = ({ data, pageContext, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
	>
		<SectorPage
			data={data}
			pageDirectory={pageContext.pageDirectory}
			trustPilotBusinessUnitReviews={
				pageContext.trustPilotBusinessUnitReviews
			}
			location={location}
		/>
	</DefaultLayout>
);

export default WrappedService;
