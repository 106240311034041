// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default';

import { Head } from '../components/templates/head';

import { AdviceBanner } from '../components/organisms/advice-banner';
import { AdviceForm } from '../components/organisms/advice-form';
import { AdviceStages } from '../components/organisms/advice-stages';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { FAQ } from '../components/organisms/faq';
import { IconCards } from '../components/organisms/icon-cards';
import { SuccessForm } from '../components/organisms/success-form';

import { MainHeader } from '../components/molecules/main-header';
import { OurSoftware } from '../components/molecules/our-software';
import { RelatedCards } from '../components/molecules/related-cards';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container';
import { TwoColText } from '../components/molecules/two-col-text';
import { pageTypes, resolveUrl } from '../utils/url-helpers';

import { ClientLogos } from '../components/atoms/client-logos';
import { Drawer } from '../components/atoms/drawer';
import { ReviewBanner } from '../components/atoms/review-banner';

import { TrustpilotReviews } from '../components/molecules/trustpilot-reviews';
import { EventMap } from '../components/organisms/event-map';
import { Testimonials } from '../components/organisms/testimonials';
import { HrefLang } from '../components/templates/hrefLang';
import { useDrawer } from '../contexts/drawer-context';
import {
	getBreadcrumbsSeoSchema,
	getFAQSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';

// markup
const ServicePage = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsServicePage.noIndex,
		noFollow: data.datoCmsServicePage.noFollow,
		metaInformation: data.datoCmsServicePage.metaInformation,
		fallbackTitle: data.datoCmsServicePage.title,
		fallbackDescription: data.datoCmsServicePage.longIntroduction,
		canonicalUrl: data.datoCmsServicePage?.canonicalUrl || location.href,
	};

	const adviceContainer = {
		...data.datoCmsServicePage.adviceContainer,
		title: data.datoCmsServicePage.adviceContainer.title.value.document
			.children,
	};

	const adviceBannerContent = {
		...data.datoCmsServicePage.adviceBanner,
		title: data.datoCmsServicePage.adviceBanner?.title.value.document
			.children,
	};

	const textWithImageContainer = {
		...data.datoCmsServicePage.textWithImageContainer,
		title: data.datoCmsServicePage.textWithImageContainer.title.value
			.document.children,
		firstContent:
			data.datoCmsServicePage.textWithImageContainer.firstContent,
		secondContent:
			data.datoCmsServicePage.textWithImageContainer.secondContent,
	};

	const textWithImageContainerOne = data.datoCmsServicePage
		.textWithImageContainerDisplayOnPageOne && {
		...data.datoCmsServicePage.textWithImageContainerOne,
		title: data.datoCmsServicePage.textWithImageContainerOne.title.value
			.document.children,
		firstContent:
			data.datoCmsServicePage.textWithImageContainerOne.firstContent,
		secondContent:
			data.datoCmsServicePage.textWithImageContainerOne.secondContent,
	};

	const textWithImageContainerTwo = data.datoCmsServicePage
		.textWithImageContainerDisplayOnPageTwo && {
		...data.datoCmsServicePage.textWithImageContainerTwo,
		title: data.datoCmsServicePage.textWithImageContainerTwo.title.value
			.document.children,
		firstContent:
			data.datoCmsServicePage.textWithImageContainerTwo.firstContent,
		secondContent:
			data.datoCmsServicePage.textWithImageContainerTwo.secondContent,
	};

	const twoColumnText = {
		...data.datoCmsServicePage.twoColumnText,
		title: data.datoCmsServicePage.twoColumnText.title.value.document
			.children,
		content:
			data.datoCmsServicePage.twoColumnText.content.value.document
				.children,
	};

	const headerCTAContent = {
		...data.datoCmsServicePage.mainHeader,
		header: data.datoCmsServicePage.mainHeader.header.value.document
			.children,
	};

	const testimonialsContent = {
		...data.datoCmsServicePage?.testimonials,
		title: data.datoCmsServicePage?.testimonials.title.value.document
			.children,
		slides: data.datoCmsServicePage?.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const faqContent = {
		title: data.datoCmsServicePage.faqContainer.title,
		subText: data.datoCmsServicePage.faqContainer.subText,
		questions: data.datoCmsServicePage.faqContainer.questions.map(
			({ question, answer }) => ({
				question,
				answer: answer.value.document.children,
			})
		),
	};

	const uspBanner = data.datoCmsServicePage.uspCtaCardsBanner;
	const uspBannerDisplayLinks =
		data.datoCmsServicePage.uspCtaCardsBannerDisplayLinks;

	const uspContainer = {
		...data.datoCmsServicePage.uspCardsContainer,
		title: data.datoCmsServicePage.uspCardsContainer.title.value.document
			.children,
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsServicePage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsServicePage?.customFormField[0]?.customFormSubtitle,
		customFormButton:
			data.datoCmsServicePage?.customFormField[0]?.customFormButton,
	};

	const textWithImage = {
		...data.datoCmsServicePage.textWithImage,
		content: data.datoCmsServicePage.textWithImage.content,
	};

	const reviewBannerContent = {
		title: data.datoCmsServicePage.reviewBannerTitle,
		subText: data.datoCmsServicePage.reviewBannerSubtext,
		reviewer: data.datoCmsServicePage.reviewBannerReviewer,
	};

	const relatedPages = data.datoCmsServicePage.relatedPages.map((page) => ({
		title: page.title,
		intro: page.intro,
		to: `/${resolveUrl(page)}`,
	}));

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.service,
			location,
			data: data.datoCmsServicePage,
		}),
		image: getImageSeoSchema(
			data.datoCmsServicePage?.mainHeader?.primaryImage
		),
		faq: getFAQSeoSchema(faqContent?.questions),
	};
	const { locale } = data.datoCmsServicePage;
	const mapLocation = {
		address: data.datoCmsServicePage?.address,
		venueLocation: data.datoCmsServicePage?.venueLocation,
	};
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsServicePage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{data.datoCmsServicePage.mainHeaderDisplayOnPage && (
				<MainHeader
					urlLocale={locale}
					drawerRef={adviceFormRef}
					{...headerCTAContent}
					reference={reference}
					response={response}
					setReference={setReference}
					setResponse={setResponse}
					rating={data.datoCmsTrustpilotRating}
				/>
			)}
			{data.datoCmsServicePage.uspCtaCardsBannerDisplayOnPage && (
				<IconCards
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					showContentLinks={uspBannerDisplayLinks}
				/>
			)}
			{data.datoCmsServicePage.textWithImageContainerDisplayOnPage && (
				<TextWithImageContainer
					locale={locale}
					textWithImageContainerContent={textWithImageContainer}
					drawerRef={adviceFormRef}
				/>
			)}
			{data.datoCmsServicePage.textWithImageContainerDisplayOnPageOne &&
				locale === 'en' && (
					<>
						{data.datoCmsServicePage.adviceBannerDisplayOnPage && (
							<AdviceBanner
								drawerRef={adviceFormRef}
								adviceBannerContent={adviceBannerContent}
							/>
						)}
						<TextWithImageContainer
							locale={locale}
							drawerRef={adviceFormRef}
							textWithImageContainerContent={
								textWithImageContainerOne
							}
						/>
					</>
				)}
			{data.datoCmsServicePage.textWithImageContainerDisplayOnPageTwo &&
				locale === 'en' && (
					<TextWithImageContainer
						locale={locale}
						textWithImageContainerContent={
							textWithImageContainerTwo
						}
					/>
				)}
			{data.datoCmsServicePage.adviceBannerDisplayOnPage &&
				locale !== 'en-CA' && (
					<AdviceBanner
						drawerRef={adviceFormRef}
						adviceBannerContent={adviceBannerContent}
					/>
				)}
			{locale !== 'en-CA' && data.datoCmsServicePage.addMapOnPage && (
				<section className="container mx-auto my-2">
					<EventMap location={mapLocation} showHeader={false} />
				</section>
			)}
			{data.datoCmsServicePage.twoColumnTextDisplayOnPage && (
				<TwoColText
					drawerRef={adviceFormRef}
					textColTextContent={twoColumnText}
				/>
			)}
			{locale !== 'en-CA' &&
				data.datoCmsServicePage.trustPilotBannerDisplayOnPage && (
					<TrustpilotReviews
						locale={locale}
						pageName={data.datoCmsServicePage.slug}
						showRatings
						reviews={data.allDatoCmsTrustpilotReview.nodes}
					/>
				)}
			{data.datoCmsServicePage.adviceContainerDisplayOnPage && (
				<AdviceStages
					drawerRef={adviceFormRef}
					adviceStagesContent={adviceContainer}
				/>
			)}
			{data.datoCmsServicePage.clientLogosBannerDisplayOnPage && (
				<ClientLogos pathname={location.pathname} />
			)}
			{data.datoCmsServicePage.uspCardsContainerDisplayOnPage && (
				<IconCards variant="Dark" iconCardsContent={uspContainer} />
			)}
			{data.datoCmsServicePage.textWithImageDisplayOnPage && (
				<TextWithImageContainer
					locale={locale}
					textWithImageContainerContent={textWithImage}
				/>
			)}
			{locale !== 'en-CA' &&
				data.datoCmsServicePage.ourSoftwareDisplayOnPage && (
					<OurSoftware
						drawerRef={adviceFormRef}
						variant="Dark"
						ourSoftwareContent={data.datoCmsServicePage.ourSoftware}
					/>
				)}
			{/* {locale !== 'en-CA' &&
				data.datoCmsServicePage
					.feefoTestimonialsBannerDisplayOnPage && (
					<FeefoTestimonials locale={locale} />
				)} */}
			{data.datoCmsServicePage.faqContainerDisplayOnPage && (
				<FAQ faqContent={faqContent} />
			)}

			{locale !== 'en-CA' &&
				data.datoCmsServicePage.reviewBannerDisplayOnPage && (
					<ReviewBanner reviewBannerContent={reviewBannerContent} />
				)}
			{locale !== 'en-CA' &&
				data.datoCmsServicePage?.testimonialsDisplayOnPage && (
					<Testimonials
						drawerRef={adviceFormRef}
						testimonialsContent={testimonialsContent}
					/>
				)}
			{locale !== 'en-CA' &&
				data.datoCmsServicePage.relatedPagesDisplayOnPage && (
					<RelatedCards
						title={data.datoCmsServicePage.relatedPagesTitle}
						relatedCards={relatedPages}
					/>
				)}
			{data.datoCmsServicePage.adviceBannerDisplayOnPage &&
				locale === 'en-CA' && (
					<AdviceBanner
						drawerRef={adviceFormRef}
						adviceBannerContent={adviceBannerContent}
					/>
				)}
			{locale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
							customFormFieldContent={customFormField}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query service($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		datoCmsTrustpilotRating(locale: { eq: $locale }) {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		allDatoCmsTrustpilotReview(
			sort: { order: ASC, fields: rating }
			filter: { source: { ne: "trustpilot" }, locale: { eq: $locale } }
		) {
			nodes {
				id
				isVerified
				locale
				originalId
				pageTag
				source
				personName
				content {
					value
				}
				rating
				title
				date(fromNow: true)
			}
		}
		datoCmsServicePage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			slug
			title
			adviceBannerDisplayOnPage
			adviceContainerDisplayOnPage
			clientLogosBannerDisplayOnPage
			feefoTestimonialsBannerDisplayOnPage
			mainHeaderDisplayOnPage
			ourSoftwareDisplayOnPage
			testimonialsDisplayOnPage
			faqContainerDisplayOnPage
			trustPilotBannerDisplayOnPage
			twoColumnTextDisplayOnPage
			uspCtaCardsBannerDisplayOnPage
			uspCardsContainerDisplayOnPage
			uspCtaCardsBannerDisplayLinks
			textWithImageDisplayOnPage
			textWithImageContainerDisplayOnPage
			textWithImageContainerDisplayOnPageOne
			textWithImageContainerDisplayOnPageTwo
			reviewBannerDisplayOnPage
			relatedPagesDisplayOnPage
			addMapOnPage
			address {
				value
			}
			venueLocation {
				latitude
				longitude
			}
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
				showInlineForm
				formPosition
				note
				ctaText
			}
			adviceContainer {
				title {
					value
				}
				content
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
				advice {
					title
					content
				}
			}
			adviceBanner {
				title {
					value
				}
				content
				ctaLabel
				ctaLink
				ctaOpenForm
			}
			twoColumnText {
				id
				title {
					value
				}
				content {
					value
				}
				primaryLinkCopy
				primaryLink
				primaryButtonOpenForm
				secondaryLinkCopy
				secondaryLink
			}
			ourSoftware {
				title
				content
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
				mobileImage {
					alt
					url
					gatsbyImageData(
						width: 230
						height: 277
						layout: CONSTRAINED
					)
				}
				desktopImage {
					alt
					url
					gatsbyImageData(
						width: 280
						height: 568
						layout: CONSTRAINED
					)
				}
				tickList {
					text
					subText
				}
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}
			faqContainer {
				title
				subText
				questions {
					question
					answer {
						value
					}
				}
			}
			uspCtaCardsBanner {
				title
				content
				linkCopy
				link
				icon
			}
			textWithImageContainer {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
				showForm
				showFirstRow
				showSecondRow
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
			textWithImageContainerOne {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
				showForm
				showFirstRow
				showSecondRow
			}
			textWithImageContainerTwo {
				id
				title {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				buttonCopy
				buttonLink
				showForm
				showFirstRow
				showSecondRow
			}
			uspCardsContainer {
				title {
					value
				}
				subText
				uspCards {
					title
					content
					linkCopy
					link
					icon
				}
			}
			textWithImage {
				id
				title
				content {
					value
				}
				buttonCopy
				buttonLink
				image {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
			}
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			reviewBannerTitle
			reviewBannerSubtext
			reviewBannerReviewer
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			reviewBannerTitle
			reviewBannerSubtext
			reviewBannerReviewer
			relatedPages {
				... on DatoCmsChildServicePage {
					internal {
						type
					}
					slug
					parentPage {
						slug
					}
					title
					intro
				}
				... on DatoCmsServicePage {
					internal {
						type
					}
					slug
					title
					intro
				}
			}
			relatedPagesTitle
		}
		datoCmsSiteSpecificSetting {
			logo {
				url
			}
		}
	}
`;

const WrappedService = ({ data, location }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<ServicePage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedService;
