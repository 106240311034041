import clsx from 'clsx';
import { renderNodeRule } from 'datocms-structured-text-to-html-string';
import { isList } from 'datocms-structured-text-utils';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { renderMarkRule, StructuredText } from 'react-datocms/structured-text';
import { gatsbyImageProp } from '../../../../types';

const RecommendationTwo = ({
	ctaLink,
	id,
	bannerTitle,
	image,
	title,
	description,
}) => {
	const ImageData = getImage(image);
	return (
		<div
			key={id}
			className="relative w-full  flex-shrink-0 px-4 py-6 bg-brand-pale-400  transition-transform duration-1000 ease-in-out"
		>
			<a href={ctaLink} className="block">
				<GatsbyImage
					image={ImageData}
					alt={title}
					className="w-52 h-24  object-cover mb-4"
				/>
				<div className="absolute glumd:ml-[35%] gluxs:ml-[35%] glu2xl:ml-[1%] glulg:-ml-[1%] glulg:overflow-hidden -rotate-12 top-4 left-1  bg-brand-red-500 text-white px-2 py-1 rounded font-semibold">
					{bannerTitle}
				</div>
				<h3 className="text-xl  font-semibold text-gray-800">
					<StructuredText
						data={title}
						customNodeRules={[
							renderNodeRule(isList, ({ node, children }) => {
								let ListTag;
								let listClassName;

								if (node.style === 'bulleted') {
									ListTag = 'ul';
									listClassName = 'list-disc';
								}
								if (node.style === 'numbered') {
									ListTag = 'ol';
									listClassName = 'list-decimal';
								}

								return (
									<ListTag
										className={clsx(
											' text-xs gap-1 font-centra-light md:text-lg px-4  ',
											listClassName
										)}
									>
										{children}
									</ListTag>
								);
							}),
						]}
						customMarkRules={[
							renderMarkRule('highlight', ({ children, key }) => (
								<span
									className="font-bold  text-brand-red-500"
									key={key}
								>
									{children}
								</span>
							)),
						]}
					/>
				</h3>
				<p className=" gluxs:text-left gluxs:ml-[40%] gluxs:w-[70%] glulg:w-full  max-w-m-screen mx-auto  gluxs:justify-center gluxs:-mr-[40%]   text-gray-600 md:text-[100%] glulg:ml-0 glumd:ml-[40%]  glumd:text-left 2xl:md:text-[100%]">
					<StructuredText
						data={description}
						customNodeRules={[
							renderNodeRule(isList, ({ node, children }) => {
								let ListTag;
								let listClassName;

								if (node.style === 'bulleted') {
									ListTag = 'ul';
									listClassName =
										'list-disc gluxs:w-[40%] glulg:w-full';
								}
								if (node.style === 'numbered') {
									ListTag = 'ol';
									listClassName =
										'list-decimal gluxs:w-[40%] glulg:w-full';
								}

								return (
									<ListTag
										className={clsx(
											' text-xs font-centra-light   gap-0 md:text-[100%] px-4 glumd:text-left glumd:items-center ',
											listClassName
										)}
									>
										{children}
									</ListTag>
								);
							}),
						]}
						customMarkRules={[
							renderMarkRule('highlight', ({ children, key }) => (
								<span
									className="font-bold text-brand-red-500"
									key={key}
								>
									{children}
								</span>
							)),
						]}
					/>
				</p>
			</a>
		</div>
	);
};

export default RecommendationTwo;

RecommendationTwo.defaultProps = {
	description: '',
	title: '',
	image: '',
	bannerTitle: '',
	ctaLink: '',
	id: '',
};

RecommendationTwo.propTypes = {
	description: PropTypes.string,
	id: PropTypes.string,
	title: PropTypes.string,
	image: PropTypes.shape({
		alt: PropTypes.string.isRequired,
		gatsbyImageData: gatsbyImageProp.isRequired,
	}),
	bannerTitle: PropTypes.string,
	ctaLink: PropTypes.string,
};
