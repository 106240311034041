import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { TrustpilotBusinessReviewHorizontal } from '../../../atoms/trustpilot-business-review';
import { LOCALE_PATHS } from '../../../../utils/locale';
import usePrivacyLink from '../../../../hooks/usePrivacyLink';

const Footer = ({ locale, rating }) => {
	const footerData = useStaticQuery(graphql`
		query footerData {
			allDatoCmsGluFooter {
				nodes {
					badgesRowOne {
						alt
						url
						gatsbyImageData(height: 80, layout: CONSTRAINED)
					}
					badgesRowTwo {
						alt
						url
						gatsbyImageData(height: 80, layout: CONSTRAINED)
					}
					id
					locale
					showTrustpilot
					title
				}
			}
		}
	`);

	const footerDataForLocale = footerData.allDatoCmsGluFooter.nodes.filter(
		(node) => node.locale === locale
	);

	const { badgesRowOne, badgesRowTwo, showTrustpilot, title } =
		footerDataForLocale[0];
	const { privacyLink } = usePrivacyLink(locale);
	return (
		<footer className="font-centra-light">
			{LOCALE_PATHS[locale] === 'ie' ? null : (
				<div className="p-5 text-center bg-brand-pale-400">
					<div>
						<h3 className="mb-5 text-2xl font-bold">{title}</h3>
					</div>
					<div className="flex flex-col items-center justify-center">
						<div className="flex items-center gap-10">
							{badgesRowOne.map((badge) => (
								<GatsbyImage
									image={badge.gatsbyImageData}
									alt={badge.alt || ''}
									key={badge.id}
								/>
							))}
						</div>
					</div>
				</div>
			)}

			{showTrustpilot && (
				<div className="flex flex-wrap max-w-m-screen font-bold max-auto items-center justify-center mx-auto py-8">
					<TrustpilotBusinessReviewHorizontal
						className="justify-center mb-10 text-center"
						rating={rating}
					/>
				</div>
			)}

			<div className="flex flex-col items-center justify-center p-5 text-sm text-white md:p-10 bg-brand-blue-400">
				<div className="flex items-center gap-4 mb-5">
					{badgesRowTwo.map((badge) => (
						<GatsbyImage
							image={badge.gatsbyImageData}
							alt={badge.alt || ''}
							key={badge.id}
						/>
					))}
				</div>
				<a
					href={privacyLink}
					target="_blank"
					rel="noreferrer"
					className="text-blue-300 underline"
				>
					Privacy policy
				</a>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	locale: 'en',
	rating: '',
};

Footer.propTypes = {
	locale: PropTypes.string,
	rating: PropTypes.string,
};

export { Footer };
