// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
	StructuredText,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { PBSLink } from '../../../atoms/link';
import VideoLogo from '../../../../assets/video-logo.svg';
import useModal from '../../../../hooks/modal';
import ModalWrapper from '../../molecules/modal-wrapper';
import VideoPlayer from '../../molecules/video-player';

const ServiceCardContainer = ({ cards }) => {
	const ref = useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);
	const [videoContent, setVideoContent] = useState(null);
	return (
		<div className="grid gap-10  py-10 -mt-1 mb-5 md:grid-cols-2 lg:grid-cols-3">
			{cards.map((card) => (
				<div
					className="flex flex-col justify-between overflow-hidden bg-white rounded-sm"
					key={card.title}
				>
					<div>
						<div className="md:max-h-[150px] overflow-hidden">
							<GatsbyImage
								image={card.image.gatsbyImageData}
								alt={card.image.alt || ''}
							/>
						</div>
						<div className="p-5">
							<h4 className="mb-4 text-xl font-bold text-black">
								{card.title}
							</h4>
							<div className="mb-4 text-black">
								<StructuredText data={card.content} />
							</div>
							<div className="flex flex-col gap-3 pt-2">
								{card.videoLinks.length > 0 &&
									card.videoLinks.map((video) => (
										<div key={video.title}>
											<button
												type="button"
												target="_blank"
												rel="noreferrer"
												className="flex flex-row items-center w-full p-4 text-left text-black border rounded-sm border-brand-pale-500"
												onClick={() => {
													setModalOpen(true);
													setVideoContent(video.id);
												}}
											>
												<img
													src={VideoLogo}
													alt="Video play"
													className="mr-2"
												/>
												{video.title}
											</button>
											{isModalOpen &&
												video.id === videoContent && (
													<ModalWrapper>
														<div
															className="relative w-7/12 m-auto "
															ref={ref}
														>
															{video.vimeoLink
																.providerUid && (
																<VideoPlayer
																	videoEmbed={
																		video.vimeoLink
																	}
																	autoPlay
																/>
															)}
														</div>
													</ModalWrapper>
												)}
										</div>
									))}
							</div>
						</div>
					</div>
					<div className="p-5 ">
						<PBSLink
							to={card.ctaLink}
							className="text-center !text-sm !block w-full"
							size="Small"
							variant="White"
							target="_blank"
						>
							{card.ctaText}
						</PBSLink>
					</div>
				</div>
			))}
		</div>
	);
};

export default ServiceCardContainer;
