/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DefaultLayout } from '../layouts/default';
import { Head } from '../components/templates/head';
import {
	getOrganizationSeoSchema,
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { getBreadcrumLocale, pageTypes } from '../utils/url-helpers';

import { MainHeader } from '../components/molecules/main-header';
import { Drawer } from '../components/atoms/drawer';
import { AdviceForm } from '../components/organisms/advice-form';
import { SuccessForm } from '../components/organisms/success-form';
import { useDrawer } from '../contexts/drawer-context';
// import { FAQ } from '../components/organisms/faq';
import { TwoColText } from '../components/molecules/two-col-text';
import FlexibleContentRow from '../components/molecules/FlexibleContentRow';
import { HrefLang } from '../components/templates/hrefLang';

const WorkLifeBalance = ({ location, data }) => {
	const { datoCmsWorkLifeBalance } = data;
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: datoCmsWorkLifeBalance.noIndex,
		noFollow: datoCmsWorkLifeBalance.noFollow,
		metaInformation: datoCmsWorkLifeBalance.metaInformation,
		fallbackTitle: datoCmsWorkLifeBalance.title,
		fallbackDescription: datoCmsWorkLifeBalance.longIntroduction,
		canonicalUrl: datoCmsWorkLifeBalance?.canonicalUrl || location.href,
	};

	const headerCTAContent = {
		...datoCmsWorkLifeBalance.mainHeader,
		header: datoCmsWorkLifeBalance.mainHeader.header.value.document
			.children,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.home,
			location,
		}),
		image: getImageSeoSchema(headerCTAContent?.primaryImage),
	};

	// const faqContent = {
	// 	title: datoCmsWorkLifeBalance.faqContainer.title,
	// 	// subText: datoCmsWorkLifeBalance.faqContainer.subText,
	// 	questions: datoCmsWorkLifeBalance.faqContainer.questions.map(
	// 		({ question, answer }) => ({
	// 			question,
	// 			answer: answer.value.document.children,
	// 		})
	// 	),
	// };

	const faqContent = {
		...datoCmsWorkLifeBalance.faqContainer,
		title: datoCmsWorkLifeBalance.faqContainer.title.value.document
			.children,
		content: datoCmsWorkLifeBalance.faqContainer.content.value,
	};
	const { locale } = data.datoCmsWorkLifeBalance;

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsWorkLifeBalance.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<MainHeader
				urlLocale={locale}
				drawerRef={adviceFormRef}
				{...headerCTAContent}
				rating={data.datoCmsTrustpilotRating}
			/>
			<section className="bg-white">
				{datoCmsWorkLifeBalance.contentRows.map((row) => (
					<FlexibleContentRow
						key={row.id}
						data={row}
						urlLocale={locale}
						setReference={setReference}
						drawerRef={adviceFormRef}
						rating={data.datoCmsTrustpilotRating}
					/>
				))}
			</section>
			{/* <FAQ faqContent={faqContent} /> */}
			{faqContent && (
				<div className="bg-brand-pale-400">
					<TwoColText
						drawerRef={adviceFormRef}
						textColTextContent={faqContent}
						markdownSupported
					/>
				</div>
			)}

			<Drawer
				label="Book my free advice call"
				ref={adviceFormRef}
				open={drawerRef === adviceFormRef}
				hasClose
				className="!p-0"
				elevation={20}
			>
				{reference === '' ? (
					<AdviceForm
						setReference={setReference}
						setResponse={setResponse}
					/>
				) : (
					<SuccessForm reference={reference} response={response} />
				)}
			</Drawer>
		</GoogleReCaptchaProvider>
	);
};

const WrappedWLB = ({ location, data, pageContext }) => (
	<DefaultLayout
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		locale={pageContext.locale}
	>
		<WorkLifeBalance location={location} data={data} />
	</DefaultLayout>
);

export default WrappedWLB;

export const query = graphql`
	query workLifeBalance($locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		datoCmsTrustpilotRating(locale: { eq: $locale }) {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsWorkLifeBalance(locale: { eq: $locale }) {
			id
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			noFollow
			noIndex
			locale
			metaInformation {
				description
				title
				twitterCard
				image {
					url
				}
			}
			canonicalUrl
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
				badge {
					alt
					url
				}
			}
			faqContainer {
				title {
					value
				}
				content {
					value
				}
			}
			contentRows {
				blueBackground
				copy {
					value
				}
				ctaText
				ctaUrl
				fullWidthBackgroundColour
				id
				image {
					alt
					url
					gatsbyImageData(
						width: 570
						height: 350
						aspectRatio: 1.63
						layout: CONSTRAINED
					)
				}
				alignImageLeft
				showTelephoneNumber
				showTrustpilot
				title {
					value
				}
				fullWidthTitle
				video {
					providerUid
					thumbnailUrl
					provider
					width
					url
					title
					height
				}
			}
		}
	}
`;
/* eslint-enable react/prop-types */
