/* eslint-disable react/prop-types */
import { useLocation } from '@reach/router';
import { Link, graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { DefaultLayout } from '../layouts/default';
import { getLocale } from '../utils/locale';

const NotFoundPage = ({ data }) => {
	const { pathname } = useLocation();
	const locale = getLocale(pathname);
	const [gtmID, setGtmID] = React.useState();
	React.useEffect(() => {
		if (locale === 'ie') {
			setGtmID(process.env.GATSBY_GTM_IE_TRACKING_ID);
		} else if (locale === 'ca') {
			setGtmID(process.env.GATSBY_GTM_CA_TRACKING_ID);
		} else {
			setGtmID(process.env.GATSBY_GTM_TRACKING_ID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<DefaultLayout
			siteSettings={data.siteSettings}
			navigation={data.navItems}
			footerData={data.footerItems}
			// pageName="PeninsulaNow"
		>
			<Helmet>
				<title>Page not found</title>
				<script>
					{`window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('consent', 'default', {
							'ad_storage': 'denied',
							'ad_user_data': 'denied',
							'ad_personalization': 'denied',
							'analytics_storage': 'denied'
						})`}
				</script>
				<script id="gtag-config">
					{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','${gtmID}');
				`}
				</script>
			</Helmet>
			<main className="max-w-screen-lg p-8 mx-auto">
				<h1 className="mb-8 text-3xl">Page not found</h1>
				<p className="mb-8">
					Sorry we couldn&apos;t find what you were looking for.
				</p>
				<p>
					<Link to="/" className="mt-8 underline text-brand-blue-300">
						Return to homepage.
					</Link>
				</p>
			</main>
		</DefaultLayout>
	);
};

export default NotFoundPage;

export const query = graphql`
	query fourOhFour {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: "en" }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: "en" }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: "en" }) {
			...footerItems
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
	}
`;
/* eslint-enable react/prop-types */
