import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

import RecommendationTwo from '../RecommendationTwo';
import { gatsbyImageProp } from '../../../../types';

const Recommendations = ({ data }) => {
	const [currentCard, setCurrentCard] = useState(0); // Track the current slide
	const [isPaused, setIsPaused] = useState(false); // Pause auto-slide on interaction
	const swipeStartRef = useRef(0);

	useEffect(() => {
		if (!isPaused) {
			const interval = setInterval(() => {
				setCurrentCard((prevCard) => (prevCard + 1) % data.length);
			}, 5000); // Auto-slide every 5 seconds
			return () => clearInterval(interval); // Cleanup on unmount
		}
	}, [data.length, isPaused]);

	// Handle wheel/trackpad swipe for navigation
	const handleWheel = (e) => {
		if (Math.abs(e.deltaX) > 50) {
			e.preventDefault(); // Prevent page scroll
			if (e.deltaX > 0) {
				setCurrentCard((prevCard) => (prevCard + 1) % data.length);
			} else {
				setCurrentCard(
					(prevCard) => (prevCard - 1 + data.length) % data.length
				);
			}
		}
	};

	// Handle mouse click for direct navigation
	const handleMouseClick = (e) => {
		const sliderWidth = e.currentTarget.offsetWidth;
		const clickPosition =
			e.clientX - e.currentTarget.getBoundingClientRect().left;
		if (clickPosition < sliderWidth / 2) {
			setCurrentCard(
				(prevCard) => (prevCard - 1 + data.length) % data.length
			);
		} else {
			setCurrentCard((prevCard) => (prevCard + 1) % data.length);
		}
	};

	// Handle touch gestures for mobile devices
	const handleTouchStart = (e) => {
		swipeStartRef.current = e.touches[0].clientX; // Record the initial touch position
	};

	const handleTouchEnd = (e) => {
		const touchEnd = e.changedTouches[0].clientX;
		const swipeDistance = swipeStartRef.current - touchEnd;
		if (Math.abs(swipeDistance) > 50) {
			if (swipeDistance > 0) {
				setCurrentCard((prevCard) => (prevCard + 1) % data.length);
			} else {
				setCurrentCard(
					(prevCard) => (prevCard - 1 + data.length) % data.length
				);
			}
		}
	};

	// Handle keyboard navigation (optional)
	useEffect(() => {
		const handleKeydown = (e) => {
			if (e.key === 'ArrowLeft') {
				setCurrentCard(
					(prevCard) => (prevCard - 1 + data.length) % data.length
				);
			} else if (e.key === 'ArrowRight') {
				setCurrentCard((prevCard) => (prevCard + 1) % data.length);
			}
		};
		window.addEventListener('keydown', handleKeydown);
		return () => window.removeEventListener('keydown', handleKeydown); // Cleanup
	}, [data.length]);

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			className="relative w-full overflow-hidden max-w-[50%] mx-auto mt-8"
			onMouseEnter={() => setIsPaused(true)}
			onMouseLeave={() => setIsPaused(false)}
			onClick={handleMouseClick}
			onTouchStart={handleTouchStart}
			onTouchEnd={handleTouchEnd}
			onWheel={handleWheel}
		>
			{/* Carousel Container */}
			<div
				className="flex transition-transform duration-300 ease-in-out"
				style={{ transform: `translateX(-${currentCard * 100}%)` }} // Smooth slide transition
			>
				{/* Render each slide */}
				{data.map((item) => (
					<RecommendationTwo
						key={item.id} // Unique key for each slide
						description={item.description}
						title={item.title}
						image={item.image}
						bannerTitle={item.bannerTitle}
						ctaLink={item.ctaLink}
					/>
				))}
			</div>
			<div className="flex flex-col max-w-m-screen mx-auto">
				<p className="left-4 gluxs:mr-56">←</p>
				<p className="right-4 ml-60 -mt-6 glulg:ml-44">→</p>
			</div>
		</div>
	);
};

export default Recommendations;

Recommendations.defaultProps = {
	data: [],
	image: '',
};

Recommendations.propTypes = {
	image: PropTypes.shape({
		alt: PropTypes.string.isRequired,
		gatsbyImageData: gatsbyImageProp.isRequired,
	}),
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			title: PropTypes.string,
			description: PropTypes.string,
			bannerTitle: PropTypes.string,
			ctaLink: PropTypes.string,
		})
	),
};
