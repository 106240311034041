// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import clsx from 'clsx';
import React, { useState } from 'react';
import ChevronIcon from '../../../assets/chevron.inline.svg';
import { buildLink } from '../../../utils/locale';
import { PBSLink } from '../link';

function ExtraFooterMenu({ heading, links, className, locale }) {
	const [expandedLinks, setExpandedLinks] = useState({});
	const [teamShow, setTeamShow] = useState(false);

	const toggleExpand = (index) => {
		setExpandedLinks((prev) => ({
			...prev,
			[index]: !prev[index],
		}));
	};
	const Component = 'span';
	return (
		<div className={className}>
			<h2 className="text-2xl font-centra-medium">{heading}</h2>
			<ul className="mt-5">
				{links
					?.filter((link) => link.headerTitle !== '')
					.map((link, i) => (
						<li key={link.id} className="mb-2">
							<div className="text-left pb-3">
								<Component
									className="font-castledown-bold inline-flex items-center  justify-center cursor-pointer"
									onClick={() => toggleExpand(i)}
								>
									{link?.headerTitle}{' '}
									<button
										type="button"
										onClick={() => setTeamShow(!teamShow)}
									>
										<ChevronIcon
											aria-hidden="true"
											className={clsx(
												'rotate-180',
												'w-[0.9rem]',
												'h-[0.45rem]',
												'lg:w-[0.625rem]',
												'lg:h-[0.313rem]',
												'ml-2',
												expandedLinks[i]
													? 'rotate-180'
													: 'rotate-0',
												'transition-transform duration-200'
												// linkAttributes["aria-expanded"] ? "rotate-0" : null
											)}
										/>
									</button>
								</Component>
								{expandedLinks[i] && (
									<ul className="mt-2">
										{link?.navLinks.map((item) => (
											<li
												key={`footer-nav-links-${item.id}`}
												className="mb-2"
											>
												<PBSLink
													to={buildLink(
														locale,
														item?.link
													)}
													variant="Unset"
													className="hover:underline text-left"
												>
													{item.title}
												</PBSLink>
											</li>
										))}
									</ul>
								)}
							</div>
						</li>
					))}
			</ul>
		</div>
	);
}

export default ExtraFooterMenu;
