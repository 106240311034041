// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { isHeading, isListItem } from 'datocms-structured-text-utils';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { StructuredText, renderNodeRule } from 'react-datocms/structured-text';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TickIcon from '../../assets/tick.inline.svg';
import { EventDate } from '../../components/atoms/event-date';
import LiveSessionCard from '../../components/glu/molecules/live-session-card';
import { BannerNav } from '../../components/glu/organisms/banner-nav';
import PageSection from '../../components/glu/organisms/page-section';
import { WebinarSignupForm } from '../../components/glu/organisms/webinar-sign-up-form';
import { ResourceHeader } from '../../components/molecules/resource-header';
import { Head } from '../../components/templates/head';
import { GLULayout } from '../../layouts/glu';
import { buildLink } from '../../utils/locale';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
	getOrganizationSeoSchema,
} from '../../utils/seo-schema';
import { getBreadcrumLocale, pageTypes } from '../../utils/url-helpers';

import Spinner from '../../assets/spinner.inline.svg';
import { UnlockAccessForm } from '../../components/glu/organisms/unlock-access-form';
import useFindCookie from '../../hooks/useCookie';

import ArticleNav from '../../components/glu/organisms/article-nav';
import ScrollToTop from '../../components/glu/atoms/ScrollToTop/scrollToTop';

const LiveWebinar = ({ data, location }) => {
	const {
		id,
		dateAndTime,
		mainLogo,
		title,
		speaker,
		category,
		fullContent,
		timeEnd,
		sessions,
		locale,
	} = data.datoCmsGluLiveWebinar;
	const { nodes: articles } = data.allDatoCmsBlogPage;
	const { nodes: gluBanner } = data.allDatoCmsGluBanner;

	const [formResponse, setFormResponse] = useState(false);
	const date = format(new Date(dateAndTime), 'MMM d, yyyy');
	const time = `${format(new Date(dateAndTime), 'h:mm')} - ${timeEnd}`;

	const resourceHeaderContent = {
		id,
		title,
		speaker,
		categories: [{ name: category?.title }],
	};

	const metaContent = {
		noIndex: data.datoCmsGluLiveWebinar.noIndex,
		noFollow: data.datoCmsGluLiveWebinar.noFollow,
		metaInformation: data.datoCmsGluLiveWebinar.metaTags,
		fallbackTitle: data.datoCmsGluLiveWebinar.eLearningTitle,
		fallbackDescription: '',
		canonicalUrl:
			data.datoCmsGluLiveWebinar?.canonicalUrl || location?.href,
	};

	const seoSchemas = {
		organization: getOrganizationSeoSchema({
			data,
			url: `${location?.origin}/${getBreadcrumLocale(location)}`,
		}),
		breadcrumbs: getBreadcrumbsSeoSchema({
			data,
			pageType: pageTypes.gluLiveWebinar,
			location,
		}),
		image: getImageSeoSchema(mainLogo?.url),
	};
	const bannerTitle = 'Love it, Share it';

	const { hasCookie, findingCookie, setFindingCookie, setHasCookie } =
		useFindCookie({
			name: 'elearningContent',
		});
	const [consentResponse, setConsentResponse] = React.useState(false);
	React.useEffect(() => {
		if (consentResponse || hasCookie) {
			setFindingCookie(false);
			setHasCookie(true);
		}
	}, [consentResponse, hasCookie, setFindingCookie, setHasCookie]);

	const { locale: urlLocale } = data.datoCmsGluLiveWebinar;

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{bannerTitle && urlLocale === 'en-IE' && (
				<BannerNav title={bannerTitle} />
			)}

			{locale === 'en' && articles.length > 0 && (
				<ArticleNav
					articleData={articles}
					locale={locale}
					bannerData={gluBanner}
				/>
			)}
			{findingCookie ? (
				<div className="flex flex-row justify-center py-10">
					<Spinner
						width="24"
						height="24"
						fill="currentColor"
						className="ml-2 animate-spin"
					/>
				</div>
			) : (
				<>
					{hasCookie || consentResponse ? (
						<>
							<PageSection>
								<p className="my-5">
									<a
										href={buildLink(
											locale,
											'/elearning/webinars'
										)}
										className="underline"
									>
										Webinars
									</a>
									<span className="mx-4">/</span>
									{title}
								</p>
								<div className="flex flex-col lg:flex-row">
									<div
										className={`w-full lg:w-1/2 ${
											!resourceHeaderContent.author
												? 'mb-20'
												: null
										}`}
									>
										<EventDate date={date} time={time} />
										<ResourceHeader
											className="!px-0"
											content={resourceHeaderContent}
											variant="Event"
										/>
										<br />
										<div className="w-full min-h-[300px]">
											<h2 className="mb-5 text-3xl font-bold">
												About this upcoming session
											</h2>
											<StructuredText
												data={fullContent.value}
												customNodeRules={[
													renderNodeRule(
														isHeading,
														({
															node,
															children,
															key,
														}) => {
															const HeadingTag = `h${node.level}`;
															return (
																<HeadingTag
																	className="mb-5 text-2xl font-bold lg:text-3xl font-castledown-regular"
																	key={key}
																>
																	{children}
																</HeadingTag>
															);
														}
													),
													renderNodeRule(
														isListItem,
														({ children, key }) => (
															<li
																className="flex items-center mt-5 mb-4 text-sm last-of-type:mb-0 font-centra-light"
																key={key}
															>
																<TickIcon
																	className="w-3 h-auto mr-4 text-blue-400 shrink-0"
																	aria-hidden="true"
																/>

																{children}
															</li>
														)
													),
												]}
											/>
										</div>
									</div>

									<div className="relative w-full px-2 py-5 lg:w-1/2 lg:p-10">
										{!formResponse ? (
											<div className="top-0 lg:absolute">
												<WebinarSignupForm
													dateAndTime={dateAndTime}
													pageUrl={
														typeof window !==
														'undefined'
															? window.location
																	.href
															: ''
													}
													setFormResponse={
														setFormResponse
													}
													formId={
														locale === 'en-IE'
															? 6736
															: 4925
													}
													locale={locale}
													pageType="elearning"
												/>
											</div>
										) : (
											<div className="flex flex-col overflow-hidden rounded-sm">
												<h3 className="w-full p-4 text-2xl text-white bg-brand-blue-400 lg:p-8">
													<TickIcon
														className="inline-block w-5 h-auto mr-4 text-white shrink-0"
														aria-hidden="true"
													/>{' '}
													Your spot has been secured
												</h3>
												<div className="p-4 bg-white lg:p-8">
													<p className="mb-5 font-bold">
														Thank you for
														registering your spot to
														our webinar{' '}
														<span className="text-brand-red-400">
															{title}
														</span>
													</p>
													<p>
														A reminder of the start
														date has been sent out
														to your registered email
														with the details of the
														webinar.
													</p>
												</div>
											</div>
										)}
									</div>
								</div>
							</PageSection>

							{sessions?.length > 0 && (
								<PageSection
									logo={mainLogo}
									titleBlock="Similar live sessions"
								>
									<div className="grid h-full grid-cols-1 gap-10 lg:grid-cols-3">
										{sessions.map((session) => (
											<div key={session.title}>
												<LiveSessionCard
													session={session}
													locale={locale}
												/>
											</div>
										))}
									</div>
								</PageSection>
							)}
						</>
					) : (
						<div className="flex flex-row justify-center py-10  items-center w-full">
							<div className="w-full lg:max-w-xl lg:shadow-xl ">
								<UnlockAccessForm
									pageUrl={
										typeof window !== 'undefined'
											? window.location.href
											: ''
									}
									locale={locale}
									setConsentResponse={setConsentResponse}
									className="!rounded-none"
									headerContent="Your expert e-learning content is waiting for you"
									subTextContent="Whether you're a client or not, access your e-learning sessions by sharing your details:"
								/>
							</div>
						</div>
					)}
				</>
			)}
			{locale === 'en' && <ScrollToTop />}
		</GoogleReCaptchaProvider>
	);
};

const WrappedLiveWebinar = ({ data, location }) => (
	<GLULayout
		locale={data.datoCmsGluLiveWebinar.locale}
		rating={data.datoCmsTrustpilotRating}
	>
		<LiveWebinar data={data} location={location} />
	</GLULayout>
);

export default WrappedLiveWebinar;

export const query = graphql`
	query GluLiveWebinar($slug: String!, $locale: String!) {
		datoCmsTrustpilotRating(locale: { eq: $locale }) {
			id
			trustscore {
				id
				originalId
				stars
				trustscore
			}
		}
		datoCmsGluLiveWebinar(slug: { eq: $slug }, locale: { eq: $locale }) {
			id
			noIndex
			locale
			metaTags {
				title
				description
				image {
					url
				}
				twitterCard
			}
			mainLogo {
				gatsbyImageData(
					placeholder: DOMINANT_COLOR
					width: 216
					height: 156
				)
				alt
			}
			category {
				title
				slug
			}
			title
			slug
			shortenedContent {
				value
			}
			fullContent {
				value
			}
			speaker {
				name
				image {
					alt
					url
					gatsbyImageData(width: 44, height: 44, layout: FIXED)
				}
				jobTitle
				linkedinUrl
				facebookUrl
				twitterUrl
				emailAddress
			}

			dateAndTime
			timeEnd
			sessions {
				title
				timeEnd
				dateAndTime
				slug
				category {
					slug
				}
				shortenedContent {
					value
				}
			}
		}
		allDatoCmsGluBanner(filter: { locale: { eq: $locale } }) {
			nodes {
				link
				id
				title
				viewTitle
				enableFlashing
			}
		}
		allDatoCmsBlogPage(
			limit: 5
			sort: { order: DESC, fields: meta___createdAt }
			filter: { locale: { eq: $locale } }
		) {
			nodes {
				title
				slug
				categories {
					slug
				}
			}
		}
	}
`;
