/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import {
	StructuredText,
	renderNodeRule,
	renderMarkRule,
	// eslint-disable-next-line import/no-unresolved
} from 'react-datocms/structured-text';
import { isHeading } from 'datocms-structured-text-utils';
import { TrustpilotBusinessReviewHorizontal } from '../../../atoms/trustpilot-business-review';
import PlayIcon from '../../../../assets/play-icon.svg';
import VideoPlaceholder from '../../../../assets/glu-video-paceholder.svg';
import useModal from '../../../../hooks/modal';
import ModalWrapper from '../../molecules/modal-wrapper';
import VideoPlayer from '../../molecules/video-player';
import Recommendations from '../../atoms/Recommendations';

const HeroWithVideo = ({
	hero,
	logo,
	privateVideoHash,
	locale,
	data,
	rating,
}) => {
	const ref = useRef();
	const { isModalOpen, setModalOpen } = useModal(ref);

	return (
		<div className="bg-brand-pale-400">
			<div className="flex flex-col px-4 py-12 mx-auto lg:flex-row max-w-m-screen">
				<div className="w-full lg:w-[60%] lg:pr-12">
					<div className="text-center lg:text-left">
						<div className="flex flex-col lg:flex-row items-center">
							<img src={logo.url} alt={logo.alt || ''} />
							<div className="flex-1 p-4 overflow-hidden  glu2xl:-ml-20 glulg:-mr-[30%] ">
								<Recommendations
									className="w-50 h-50 "
									data={data}
								/>
							</div>
						</div>

						{hero.trustpilotReview && (
							<div className=" flex items-start my-5">
								<TrustpilotBusinessReviewHorizontal
									className="mb-10"
									rating={rating}
								/>
							</div>
						)}
					</div>
					{hero.mainTitle && (
						<div className="text-lg lg:pr-10 lg:text-2xl">
							<StructuredText
								data={hero.mainTitle}
								customNodeRules={[
									renderNodeRule(
										isHeading,
										({ node, children, key }) => {
											const HeadingTag = `h${node.level}`;
											return (
												<HeadingTag
													className="mb-5 text-4xl lg:text-5xl font-castledown-regular !leading-[3.5rem]"
													key={key}
												>
													{children}
												</HeadingTag>
											);
										}
									),
								]}
								customMarkRules={[
									renderMarkRule(
										'highlight',
										({ children, key }) => (
											<span
												className="text-brand-red-400 pb-1 bg-no-repeat bg-underline bg-bottom bg-[length:100%_6px] md:bg-[length:100%_8px]"
												key={key}
											>
												{children}
											</span>
										)
									),
								]}
							/>
						</div>
					)}
					{hero.mainSubheading && (
						<div className="text-lg lg:pr-10 lg:text-2xl">
							<StructuredText
								data={hero.mainSubheading}
								customMarkRules={[
									renderMarkRule(
										'highlight',
										({ children, key }) => (
											<span
												className="font-bold text-brand-red-400"
												key={key}
											>
												{children}
											</span>
										)
									),
								]}
							/>
						</div>
					)}
				</div>
				<div className="w-full lg:w-[40%] p-4">
					<div className="pt-10 mt-8 text-center lg:text-left lg:pt-2">
						<button
							type="button"
							onClick={() => setModalOpen(true)}
							tabIndex={0}
							className="relative"
						>
							<img
								src={VideoPlaceholder}
								className="shadow-2xl"
								alt="Video placeholder"
							/>
							{hero.videoOverlayText && (
								<p className="absolute -left-3 bg-[#D7DDF9] p-1 xs:p-3 top-2 -rotate-6 text-sm md:text-xl shadow-2xl">
									<span>
										<img
											src={PlayIcon}
											alt="Play symbol"
											className="inline-block mr-4"
										/>
									</span>
									{hero.videoOverlayText}
								</p>
							)}
						</button>
					</div>
				</div>
			</div>
			{isModalOpen && (
				<ModalWrapper>
					<div
						className="relative w-7/12 m-auto opacity-100"
						ref={ref}
					>
						{hero.vimeoLink?.providerUid && (
							<VideoPlayer
								videoEmbed={hero.vimeoLink}
								autoPlay
								privateVideoHash={privateVideoHash}
								locale={locale}
							/>
						)}
					</div>
				</ModalWrapper>
			)}
		</div>
	);
};
export default HeroWithVideo;
