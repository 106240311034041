// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React from 'react';
import ArticleNav from '../article-nav';
import ChecklistWithImage from '../checklist-with-image';
import DoubleTextBlock from '../double-text-block';
import HeroWithVideo from '../hero-with-video';
import PageSection from '../page-section';
import ServiceCardContainer from '../service-card-container';
// import WhatsIncluded from '../whats-included';

const ProspectLocked = ({ data, setModalOpen, rating }) => {
	const {
		hero: heroContent,
		intro,
		// onDemandAndWebinars,
		// whatSIncludedTitle,
		// whatSIncludedImage,
		// whatsIncludedContent,
		checklistImage,
		checklist,
		locale,
	} = data.datoCmsGluProspect;

	const { nodes: articles } = data.allDatoCmsBlogPage;
	const { nodes: gluBanner } = data.allDatoCmsGluBanner;

	const { nodes: liveWebinars } = data.allDatoCmsGluCourse;
	const { mainLogo } = data.allDatoCmsGluLogo.nodes[0];

	const { otherServicesTitle, otherServices } = data.datoCmsGluProspect;

	return (
		<>
			{locale === 'en' && articles.length > 0 && (
				<ArticleNav
					articleData={articles}
					locale={locale}
					bannerData={gluBanner}
				/>
			)}
			{locale === 'en-IE' && liveWebinars.length > 0 && (
				<ArticleNav articleData={liveWebinars} locale={locale} />
			)}
			<HeroWithVideo
				hero={heroContent}
				logo={mainLogo}
				privateVideoHash="9ace720501"
				locale={locale}
				rating={rating}
			/>
			<DoubleTextBlock textBlocks={intro.doubleTextBlock[0]} />
			{/* <PageSection
				theme="White"
				titleBlock={whatSIncludedTitle.value}
				logo={mainLogo}
				size="Large"
			>
				<WhatsIncluded
					content={whatsIncludedContent}
					image={whatSIncludedImage}
					onClick={() => {
						setModalOpen(true);
					}}
					onDemandAndWebinars={onDemandAndWebinars}
				/>
			</PageSection> */}
			<PageSection>
				<ChecklistWithImage
					listImage={checklistImage}
					content={checklist}
					onClick={() => {
						setModalOpen(true);
					}}
				/>
			</PageSection>
			<PageSection theme="Dark" titleBlock={otherServicesTitle.value}>
				<ServiceCardContainer cards={otherServices} />
			</PageSection>
		</>
	);
};

export default ProspectLocked;
