import React, { useState, useEffect } from 'react';
import { Button } from '../../../atoms/button';

const ScrollToTop = () => {
	const [isVisible, setIsVisible] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	const toggleVisibility = () => {
		if (window.scrollY > 300) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);

		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	return (
		<div className="fixed bottom-4 right-4 gluxs:mr-[5.5%] gluxs:mb-[45%] glusm:ml-5 glumd:mb-[24%] glumd:mr-[2.5%] glulg:mb-[20%] glulg:-mr-[3%] glumdx:mr-[2.5%] glumdx:mb-[18%] glusm:mb-[30%] glusm:-mr-[1.5%] glumds:mb-[20%] glumds:mr-[2.5%] glulg:mr-[2%] glulg:mb-[18%] gluxl:mb-[15%] gluxl:mr-5 glu2xl:mb-[10%] glu2xl:mr-6 glulgx:mb-[14%] glulgx:mr-5">
			{isVisible && (
				<Button
					onClick={scrollToTop}
					className="!bottom-5 !w-14 !h-14 !flex  !items-center !justify-center !right-5 !text-white  hover:!bg-brand-blue-400 !border-none !bg-blue-400 !p-2 !rounded-full !shadow-lg "
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-20 w-20"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						{' '}
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M5 15l7-7 7 7"
						/>{' '}
					</svg>
				</Button>
			)}
		</div>
	);
};

export default ScrollToTop;
