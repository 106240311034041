export const getCanonicalUrl = () => {
	const isBrowser = typeof window !== 'undefined';

	if (isBrowser) {
		// Get the current URL from window.location.href
		const url = new URL(window.location.href);

		// Step 1: Enforce HTTPS (redirect if not already HTTPS)
		if (url.protocol !== 'https:') {
			url.protocol = 'https:';
		}

		// Step 2: Enforce www or non-www version (let's standardize to www in this example)
		// Add 'www' if missing, or remove it if it's present.
		if (!url.hostname.startsWith('www.')) {
			url.hostname = `www.${url.hostname}`;
		}

		// Step 3: Remove query parameters (if they don't impact content, e.g., for tracking)
		url.search = ''; // Removing all query parameters

		// Step 4: Remove fragment identifiers (hashes)
		url.hash = '';

		// Step 5: Return the canonical URL as a string
		return url.toString();
	}

	return null;
};
