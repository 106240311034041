// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BreadCrumbs } from '../components/atoms/breadcrumbs';
import { MainHeader } from '../components/molecules/main-header-compass';
import * as headingStyles from '../components/molecules/main-header/index.module.css';
import CtaSection from '../components/organisms/Cta-section-compass';
import StatementCompass from '../components/organisms/statement-compass';
import ValuesCompass from '../components/organisms/values-compass';
import { Head } from '../components/templates/head';
import { DefaultLayout } from '../layouts/default';
import { createHeadingHighlight } from '../utils/heading-highlight';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { getBreadcrumbs, pageTypes } from '../utils/url-helpers';

const PeninsulaCompassPage = ({ data, location }) => {
	const metaContent = {
		noIndex: data.datoCmsCompassPage?.noIndex,
		noFollow: data.datoCmsCompassPage?.noFollow,
		metaInformation: data.datoCmsCompassPage?.metaInformation,
		fallbackTitle: data.datoCmsCompassPage?.title,
		fallbackDescription: data.datoCmsCompassPage?.longIntroduction,
		canonicalUrl: data.datoCmsCompassPage?.canonicalUrl || location.href,
	};
	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.compass,
			location,
			data: data.datoCmsCompassPage,
		}),
		image: getImageSeoSchema(
			data.datoCmsCompassPage?.mainHeader?.primaryImage
		),
	};
	const breadCrumbItems = getBreadcrumbs(
		data.datoCmsCompassPage.internal.type,
		data.datoCmsCompassPage
	);
	const titleText = createHeadingHighlight({
		headings:
			data.datoCmsCompassPage.mainHeader[0].titleHeader.value.document
				.children[0].children,
		headingStyles,
	});
	const valuesContent = {
		valuesPrimaryList: data.datoCmsCompassPage.valuesPrimaryList,
		valuesPrimaryImage: data.datoCmsCompassPage.valuesPrimaryImage,
		valuesSecondaryList: data.datoCmsCompassPage.valuesSecondaryList,
		valuesSecondaryImage: data.datoCmsCompassPage.valuesSecondaryImage,
	};
	const statementContent = {
		statementTitle: data.datoCmsCompassPage.statementTitle,
		statementSubHeader: data.datoCmsCompassPage.statementSubHeader,
		statement: data.datoCmsCompassPage.statement,
	};
	const ctaSectionContent = {
		ctaTitle: data.datoCmsCompassPage.ctaTitle,
		ctaSubHeading: data.datoCmsCompassPage.ctaSubHeading,
		ctaButtons: data.datoCmsCompassPage.ctaButtons,
	};
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			<BreadCrumbs breadCrumbItems={breadCrumbItems} />
			{data.datoCmsCompassPage.displayOnPageMainHeader && (
				<MainHeader
					heroHeader={data.datoCmsCompassPage.mainHeader[0]}
					headingStyles={headingStyles}
					titleText={titleText}
				/>
			)}
			{data.datoCmsCompassPage.displayOnPageStatement && (
				<StatementCompass statementContent={statementContent} />
			)}
			{data.datoCmsCompassPage.displayOnPageValueList && (
				<section className="px-3">
					<ValuesCompass
						valuesContent={valuesContent}
						className="xl:mx-5 my-20"
					/>
				</section>
			)}
			{data.datoCmsCompassPage.displayOnPageCtaSection && (
				<CtaSection ctaSectionContent={ctaSectionContent} />
			)}
		</GoogleReCaptchaProvider>
	);
};

const WrappedPeninsulaCompass = ({ data, location }) => (
	<DefaultLayout
		className="bg-white"
		siteSettings={data.siteSettings}
		navigation={data.navItems}
		footerData={data.footerItems}
		pageName="PeninsulaNow"
	>
		<PeninsulaCompassPage data={data} location={location} />
	</DefaultLayout>
);

export default WrappedPeninsulaCompass;
export const query = graphql`
	query CompassPage($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
			extraFooterNavigation {
				id
				headerTitle
				navLinks {
					id
					link
					title
				}
			}
		}
		datoCmsCompassPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			id
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			slug
			internal {
				type
			}
			title
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			displayOnPageCtaSection
			displayOnPageMainHeader
			displayOnPageStatement
			displayOnPageValueList
			mainHeader {
				titleHeader {
					value
				}
				subHeading
				content {
					value
				}
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 600
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 2481
						height: 1754
						layout: CONSTRAINED
					)
				}
				ctaText
				id
				originalId
			}
			ctaTitle
			ctaSubHeading
			ctaButtons {
				title
				locale
				link
				id
				originalId
			}
			statementTitle
			statementSubHeader
			statement {
				id
				originalId
				image {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				content
			}
			valuesPrimaryImage {
				alt
				url
				gatsbyImageData(width: 380, height: 330, layout: CONSTRAINED)
			}
			valuesPrimaryList {
				id
				text
				originalId
			}
			valuesSecondaryImage {
				alt
				url
				gatsbyImageData(width: 380, height: 330, layout: CONSTRAINED)
			}
			valuesSecondaryList {
				id
				originalId
				text
			}
		}
		datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			logo {
				url
			}
		}
	}
`;
