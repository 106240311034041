/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { DrawerProvider } from '../../contexts/drawer-context';
import { ScreenResizerProvider } from '../../contexts/screen-resize-context';
import { MarketoProvider } from '../../contexts/marketo-context';

import { MasterLayout } from '../master';
import { Footer } from '../../components/glu/organisms/footer';
import { LogoHeader } from '../../components/glu/organisms/logo-header';
import { Header } from '../../components/organisms/header';

const GLULayout = ({
	children,
	className,
	logoContent,
	locale = 'en',
	rating,
}) => (
	<ScreenResizerProvider>
		<DrawerProvider>
			<MarketoProvider>
				<MasterLayout>
					{logoContent ? (
						<LogoHeader
							siteTitle="Peninsula Group"
							logoContent={logoContent}
							homepageUrl="/"
						/>
					) : (
						<Header
							siteTitle="E-learning"
							mobileOnlyHamburger
							homepageUrl="/elearning/"
							locale={locale}
							gluLayout
						/>
					)}

					<main id="main" className={className}>
						{children}
					</main>
					<Footer locale={locale} rating={rating} />
				</MasterLayout>
			</MarketoProvider>
		</DrawerProvider>
	</ScreenResizerProvider>
);

GLULayout.defaultProps = {
	className: '',
};

GLULayout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

export { GLULayout };
