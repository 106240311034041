import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { useDrawer } from '../../../contexts/drawer-context';
import { pricingCardProps } from '../../../types';

import { Drawer } from '../../atoms/drawer';
import { Title } from '../../atoms/title';
import { TrustpilotBusinessReviewHorizontal } from '../../atoms/trustpilot-business-review';

import { PricingCards } from '../../molecules/pricing-cards';

import { PricingCalculator } from '../../molecules/pricing-calculator';
import { PricingFormCA } from '../ca/forms/salesforce/pricing';
import { PricingDetailsFormCA } from '../ca/forms/salesforce/pricing-details';
import { PricingDetailsForm } from '../pricing-details-form';
import { PricingForm } from '../pricing-form';

const PricingSection = ({ pricingContent, locale, rating }) => {
	const { drawerRef } = useDrawer();
	const priceQuoteFormRef = useRef(null);

	const [priceCalculated, setPriceCalculated] = useState({
		combined: '-',
		hr: '-',
		hs: '-',
	});
	const [employeeCount, setEmployeeCount] = useState(0);
	const [formTitle, setFormTitle] = useState('');
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const { title, subtitle, cards, feeGuide } = pricingContent;

	return (
		<section className="relative w-full">
			<div className="px-3 py-16 mx-auto max-w-m-screen">
				<Title title={title} />
				<PricingCalculator
					title={title}
					subtitle={subtitle}
					feeGuide={feeGuide}
					setPriceCalculated={setPriceCalculated}
					setEmployeeCount={setEmployeeCount}
				/>
				<PricingCards
					priceCalculated={priceCalculated}
					content={cards}
					employeeCount={employeeCount}
					drawerRef={priceQuoteFormRef}
					setFormTitle={setFormTitle}
				/>

				<div className="max-w-md px-3 mx-auto mt-12 md:mt-20">
					{locale !== 'en-CA' ? (
						<TrustpilotBusinessReviewHorizontal
							locale={locale}
							className="justify-center mb-10 text-center md:mb-16"
							rating={rating}
						/>
					) : null}

					<p className="mt-16 text-xl text-center font-centra-light">
						*Pricing is dependent on your number of employees and
						excludes VAT.
					</p>
					<p className="mt-8 text-xl text-center font-centra-light">
						The above service pricing is based on our mid-level
						product over a five-year term. One to five year terms
						are available. Dependent on your industry or business
						circumstances and the service term you require, the fee
						may vary above or below the example fee.
					</p>
				</div>

				{locale !== 'en-CA' ? (
					<Drawer
						label="Get my quote form modal"
						ref={priceQuoteFormRef}
						open={drawerRef === priceQuoteFormRef}
						className="!p-0"
						hasClose
						onClose={() => setReference('')}
					>
						{reference === '' ? (
							<PricingForm
								formTitle={formTitle}
								setReference={setReference}
								setResponse={setResponse}
								employeeCount={employeeCount}
							/>
						) : (
							<PricingDetailsForm
								reference={reference}
								response={response}
								setReference={setReference}
							/>
						)}
					</Drawer>
				) : (
					<Drawer
						label="Get my quote form modal"
						ref={priceQuoteFormRef}
						open={drawerRef === priceQuoteFormRef}
						className="!p-0"
						hasClose
						onClose={() => setReference('')}
					>
						{reference === '' ? (
							<PricingFormCA
								formTitle={formTitle}
								setReference={setReference}
								setResponse={setResponse}
								employeeCount={employeeCount}
							/>
						) : (
							<PricingDetailsFormCA
								reference={reference}
								response={response}
								setReference={setReference}
							/>
						)}
					</Drawer>
				)}
			</div>
		</section>
	);
};

PricingSection.propTypes = {
	pricingContent: PropTypes.shape({
		title: PropTypes.string,
		subtitle: PropTypes.string,
		cards: PropTypes.arrayOf(pricingCardProps),
		feeGuide: PropTypes.shape({
			hr: PropTypes.string,
			hs: PropTypes.string,
			combined: PropTypes.string,
		}),
	}).isRequired,
	locale: PropTypes.string.isRequired,
	rating: PropTypes.string.isRequired,
};

export { PricingSection };
