// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React, { useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ClientLogos } from '../components/atoms/client-logos-ppc';
import { Drawer } from '../components/atoms/drawer';
import { MainHeader } from '../components/molecules/main-header-ppc';
import { MainHeaderUK } from '../components/molecules/main-header-ppc-uk';
import { TextWithImageContainer } from '../components/molecules/text-with-image-container-ppc';
import { AdviceForm } from '../components/organisms/advice-form-ppc';
import { AdviceFormCA } from '../components/organisms/ca/forms/salesforce/advice-ppc';
import { SuccessFormCA } from '../components/organisms/ca/forms/salesforce/success';
import { IconCards } from '../components/organisms/icon-cards-ppc';
import { SuccessForm } from '../components/organisms/success-form';
import { Testimonials } from '../components/organisms/testimonials-ppc';
import { Head } from '../components/templates/head';
import { HrefLang } from '../components/templates/hrefLang';
import { useDrawer } from '../contexts/drawer-context';
import { DefaultLayout } from '../layouts/default/ppc';
import {
	getBreadcrumbsSeoSchema,
	getImageSeoSchema,
} from '../utils/seo-schema';
import { pageTypes } from '../utils/url-helpers';

// markup
const PpcPage = ({ data, location }) => {
	const { drawerRef } = useDrawer();
	const adviceFormRef = useRef(null);
	const [reference, setReference] = useState('');
	const [response, setResponse] = useState('');

	const metaContent = {
		noIndex: data.datoCmsPpcPage.noIndex,
		noFollow: data.datoCmsPpcPage.noFollow,
		metaInformation: data.datoCmsPpcPage.metaInformation,
		fallbackTitle: data.datoCmsPpcPage.title,
		fallbackDescription: data.datoCmsPpcPage.longIntroduction,
		canonicalUrl: data.datoCmsPpcPage?.canonicalUrl || location.href,
	};

	const textWithImageContainer = {
		...data.datoCmsPpcPage.textWithImageContainer,
		firsttitle:
			data.datoCmsPpcPage.textWithImageContainer?.firstTitle?.value
				.document.children,
		secondtitle:
			data.datoCmsPpcPage.textWithImageContainer?.secondTitle.value
				.document.children,
		thirdtitle:
			data.datoCmsPpcPage.textWithImageContainer?.thirdTitle.value
				.document.children,
		firstContent: data.datoCmsPpcPage.textWithImageContainer?.firstContent,
		secondContent:
			data.datoCmsPpcPage.textWithImageContainer?.secondContent,
		thirdContent: data.datoCmsPpcPage.textWithImageContainer?.thirdContent,
	};

	const headerCTAContent = {
		...data.datoCmsPpcPage.mainHeader,
		header: data.datoCmsPpcPage.mainHeader.header.value.document.children,
		googleReview: data.siteSettings.googleReview,
	};

	const { uspTitle } = data.datoCmsPpcPage;
	const uspBanner = data.datoCmsPpcPage.uspCtaCardsBanner;
	const uspBannerDisplayLinks =
		data.datoCmsPpcPage.uspCtaCardsBannerDisplayLinks;

	const testimonialsContent = {
		...data.datoCmsPpcPage.testimonials,
		title: data.datoCmsPpcPage.testimonials.title.value.document.children,
		slides: data.datoCmsPpcPage.testimonials.slides.map((slide) => ({
			...slide,
			tags: slide.tags.split(','),
		})),
	};

	const seoSchemas = {
		breadcrumbs: getBreadcrumbsSeoSchema({
			pageType: pageTypes.service,
			location,
			data: data.datoCmsPpcPage,
		}),
		image: getImageSeoSchema(data.datoCmsPpcPage?.mainHeader?.primaryImage),
		// faq: getFAQSeoSchema(faqContent?.questions),
	};

	const customFormField = {
		customFormTitle:
			data.datoCmsPpcPage?.customFormField[0]?.customFormTitle,
		customFormSubtitle:
			data.datoCmsPpcPage?.customFormField[0]?.customFormSubtitle,
		customFormButton:
			data.datoCmsPpcPage?.customFormField[0]?.customFormButton,
	};

	const { locale: urlLocale } = data.datoCmsPpcPage;
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<HrefLang locales={data.datoCmsPpcPage.allSeoLocales} />
			<Head metaContent={metaContent} seoSchemas={seoSchemas} />
			{urlLocale === 'en-CA' ? (
				<MainHeader drawerRef={adviceFormRef} {...headerCTAContent} />
			) : (
				<MainHeaderUK
					drawerRef={adviceFormRef}
					{...headerCTAContent}
					setReference={setReference}
					setResponse={setResponse}
					showFeefooBanner={
						data.datoCmsPpcPage.feefoTestimonialsBannerDisplayOnPage
					}
					customFormField={customFormField}
					reference={reference}
					response={response}
				/>
			)}
			{data.datoCmsPpcPage.uspCtaCardsBannerDisplayOnPage && (
				<IconCards
					uspTitle={uspTitle}
					iconCardsContent={{
						uspCards: uspBanner,
					}}
					showContentLinks={uspBannerDisplayLinks}
				/>
			)}
			{data.datoCmsPpcPage.textWithImageContainerDisplayOnPage && (
				<TextWithImageContainer
					textWithImageContainerContent={textWithImageContainer}
					drawerRef={adviceFormRef}
				/>
			)}

			{data.datoCmsPpcPage.clientLogosBannerDisplayOnPage && (
				<ClientLogos pathname={location.pathname} />
			)}
			<Testimonials
				drawerRef={adviceFormRef}
				testimonialsContent={testimonialsContent}
			/>

			{/* {urlLocale !== 'en-CA' &&
				data.datoCmsPpcPage.feefoTestimonialsBannerDisplayOnPage && (
					<FeefoTestimonials locale={urlLocale} />
				)} */}

			{urlLocale !== 'en-CA' ? (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceForm
							setReference={setReference}
							setResponse={setResponse}
							customFormField={customFormField}
						/>
					) : (
						<SuccessForm
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			) : (
				<Drawer
					label="Book my free advice call"
					ref={adviceFormRef}
					open={drawerRef === adviceFormRef}
					hasClose
					className="!p-0"
					elevation={20}
				>
					{reference === '' ? (
						<AdviceFormCA
							setReference={setReference}
							setResponse={setResponse}
							customFormField={customFormField}
						/>
					) : (
						<SuccessFormCA
							reference={reference}
							response={response}
						/>
					)}
				</Drawer>
			)}
		</GoogleReCaptchaProvider>
	);
};

export const query = graphql`
	query service($slug: String!, $locale: String!) {
		siteSettings: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...siteSettings
			googleReview {
				url
				alt
			}
		}
		navItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...navItems
			sideNavigation {
				treeChildren {
					title
					root
					position
					link
					id
					icon
					locale
				}
			}
		}
		footerItems: datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			...footerItems
		}
		datoCmsPpcPage(slug: { eq: $slug }, locale: { eq: $locale }) {
			originalId
			locale
			allSeoLocales: _allMetaInformationLocales {
				locale
			}
			slug
			title
			uspTitle
			clientLogosBannerDisplayOnPage
			feefoTestimonialsBannerDisplayOnPage
			mainHeaderDisplayOnPage
			uspCtaCardsBannerDisplayOnPage
			uspCtaCardsBannerDisplayLinks
			textWithImageContainerDisplayOnPage
			mainHeader {
				header {
					value
				}
				secondaryHeader
				primaryLinkCopy
				primaryLink
				primaryLinkOpenForm
				secondaryLinkCopy
				secondaryLink
				primaryImage {
					alt
					url
					gatsbyImageData(
						width: 400
						height: 400
						layout: CONSTRAINED
					)
				}
				secondaryImage {
					alt
					url
					gatsbyImageData(
						width: 380
						height: 330
						layout: CONSTRAINED
					)
				}
				showTrustPilot
				showInlineForm
				formPosition
				ctaText
			}
			uspCtaCardsBanner {
				title
				content
				linkCopy
				link
				icon
			}

			textWithImageContainer {
				id
				firstTitle {
					value
				}
				firstContent {
					value
				}
				firstImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				firstButtonCopy
				firstDisplayCtaOnPage
				secondTitle {
					value
				}
				secondContent {
					value
				}
				secondImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				secondButtonCopy
				secondDisplayCtaOnPage
				thirdTitle {
					value
				}
				thirdContent {
					value
				}
				thirdImage {
					alt
					url
					gatsbyImageData(
						width: 600
						height: 460
						layout: CONSTRAINED
					)
				}
				thirdButtonCopy
				thirdDisplayCtaOnPage
			}
			testimonials {
				id
				title {
					value
				}
				subText
				slides {
					id
					image {
						alt
						gatsbyImageData(
							aspectRatio: 45
							placeholder: DOMINANT_COLOR
							width: 400
							sizes: "(max-width: 640px) 400px, 800px"
							height: 500
						)
					}
					quote
					nameAndJob
					company
					tags
				}
				primaryLinkCopy
				secondaryLinkCopy
				secondaryLink
			}

			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			canonicalUrl
			noIndex
			noFollow
			metaInformation {
				title
				description
				image {
					url
				}
				twitterCard
			}
			customFormField {
				customFormTitle
				customFormSubtitle
				customFormButton
			}
		}
		datoCmsSiteSpecificSetting(locale: { eq: $locale }) {
			logo {
				url
			}
			footerImageCa {
				url
				alt
			}
			footerImage1 {
				url
				alt
			}
			footerImage2 {
				url
				alt
			}
			footerImage3 {
				url
				alt
			}
			footerImage4 {
				url
				alt
			}
			footerImage5 {
				url
				alt
			}
		}
	}
`;

const WrappedPpc = ({ data, location }) => {
	const footerImages = {
		footerImageCA: data.datoCmsSiteSpecificSetting.footerImageCa,
		footerImage1: data.datoCmsSiteSpecificSetting.footerImage1,
		footerImage2: data.datoCmsSiteSpecificSetting.footerImage2,
		footerImage3: data.datoCmsSiteSpecificSetting.footerImage3,
		footerImage4: data.datoCmsSiteSpecificSetting.footerImage4,
		footerImage5: data.datoCmsSiteSpecificSetting.footerImage5,
	};
	return (
		<DefaultLayout
			siteSettings={data.siteSettings}
			navigation={data.navItems}
			footerData={data.footerItems}
			footerImages={footerImages}
		>
			<PpcPage data={data} location={location} />
		</DefaultLayout>
	);
};

export default WrappedPpc;
