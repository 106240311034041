/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useScreenResizer } from '../../../contexts/screen-resize-context';
import { useDrawer } from '../../../contexts/drawer-context';

import { Drawer } from '../../atoms/drawer';

import { NavMobile } from '../../organisms/nav-mobile-ppc-v2-ca';

import { refProp } from '../../../types';

const SideNav = ({ linkTitle, links, navRef }) => {
	const { drawerRef } = useDrawer();
	const [isDesktop, setIsDesktop] = useState(null);
	const { width } = useScreenResizer();

	useEffect(() => {
		if (width > 1024) {
			setIsDesktop(true);
		} else {
			setIsDesktop(false);
		}
	}, [width]);

	return !isDesktop && links ? (
		<Drawer
			label="More Menu"
			ref={navRef}
			open={drawerRef === navRef}
			elevation={20}
			hasClose
		>
			<div className="px-10">
				{links[0] && links[0].length > 0 ? (
					<>
						<NavMobile
							linkTitle={linkTitle}
							aria-label="Main menu"
							links={links[0]}
						/>
						<hr className="mx-4 mb-6 border-brand-pale-400" />
					</>
				) : null}
			</div>
		</Drawer>
	) : null;
};

SideNav.defaultProps = {
	links: [],
};

SideNav.propTypes = {
	links: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				link: PropTypes.string.isRequired,
				title: PropTypes.string.isRequired,
			})
		),
		PropTypes.arrayOf(
			PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					link: PropTypes.string,
					treeChildren: PropTypes.arrayOf(
						PropTypes.shape({
							title: PropTypes.string.isRequired,
							subText: PropTypes.string.isRequired,
							link: PropTypes.string.isRequired,
							icon: PropTypes.string.isRequired,
						})
					),
				})
			),
			PropTypes.arrayOf(
				PropTypes.shape({
					link: PropTypes.string.isRequired,
					title: PropTypes.string.isRequired,
				})
			)
		),
		PropTypes.array,
	]),
	navRef: refProp.isRequired,
};

export { SideNav };
