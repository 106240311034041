/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { useDrawer } from '../../../contexts/drawer-context';
import { useScreenResizer } from '../../../contexts/screen-resize-context';

import { NavDesktop } from '../nav-desktop-ppc-v2-ca';
import { CallUs } from '../../atoms/call-us-ppc-v2-ca';
import { Hamburger } from '../../atoms/hamburger';
import { Logo } from '../../atoms/logo-ppc-v2-ca';
import { SideNav } from '../../molecules/side-nav-ppc-v2-ca';

import { sendTrackingData } from '../../../utils';

// Creating new navigation for Canada.
const shortLocale = {
	en: 'en',
	'en-IE': 'ie',
	'en-CA': 'ca',
};

const HeaderCA = ({
	siteTitle,
	isStorybook,
	headerContent,
	siteSettings,
	locale,
	pageName,
	disableCallUs = false,
}) => {
	const { drawerRef, setDrawerRef } = useDrawer();
	const navRef = useRef(null);
	const { width } = useScreenResizer();
	const [sideLinks, setSideLinks] = useState([]);
	const ppcCategory = headerContent?.category || '';

	const headerNavigationItems = headerContent?.headerNavigationItems;

	const links2Side =
		headerNavigationItems?.map((obj) => ({
			...obj,
			link: obj.link ? `${obj.link}?category=${ppcCategory}` : obj.link,
		})) || [];

	const primaryNavElementCheck = useStaticQuery(graphql`
		query primaryNavElementCheck {
			allDatoCmsGluWebinarsPage {
				nodes {
					locale
				}
			}
			allDatoCmsGluClientPage(sort: { fields: originalId, order: ASC }) {
				nodes {
					slug
					locale
					title
				}
			}
			allDatoCmsGluGossip {
				nodes {
					locale
				}
			}
		}
	`);

	const {
		allDatoCmsGluClientPage: clientPages,
		allDatoCmsGluWebinarsPage: webinarsPage,
	} = primaryNavElementCheck;

	const primaryNavigationArray = [];

	const clientPagesForLocale = clientPages.nodes.filter(
		(node) => node.locale === locale
	);

	clientPagesForLocale.forEach((page) => {
		const primaryNavigationObject = {
			title: page.title,
			link:
				locale === 'en'
					? `/elearning/${page.slug}`
					: `/${shortLocale[locale]}/elearning/${page.slug}`,
			treeChildren: [],
		};
		primaryNavigationArray.push(primaryNavigationObject);
	});

	webinarsPage.nodes.forEach((page) => {
		if (page.locale !== locale) {
			return;
		}
		const primaryNavigationObject = {
			title: 'Webinars',
			link:
				locale === 'en'
					? '/elearning/webinars'
					: `/${shortLocale[locale]}/elearning/webinars`,
			treeChildren: [],
		};
		primaryNavigationArray.push(primaryNavigationObject);
	});

	if (!isStorybook) {
		useEffect(() => {
			if (width > 1024) {
				setSideLinks(links2Side || headerContent.sideNavigation);
			} else {
				setSideLinks([links2Side || headerContent.primaryNavigation]);
			}
		}, [width, headerContent]);
	}

	const handleClickTracking = (trackData) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', trackData);
	};

	return (
		<header className="sticky top-0 z-50 bg-white">
			<section className="relative flex items-center justify-between w-full gap-2 p-4 mx-auto max-w-m-screen">
				<div className="flex items-center lg:py-6 header-left">
					<Logo
						width={126}
						height={36}
						title={siteTitle}
						url={headerContent?.homeLink}
					/>
					<NavDesktop
						aria-label="Main menu"
						className="hidden lg:lg:ml-lg-f xl:ml-xl-f lg:block"
						linkTitle={headerContent?.title}
						links={links2Side}
						drawerOpen={drawerRef === navRef}
					/>
				</div>
				<div className="flex items-center header-right">
					{!disableCallUs && width > 1024 && (
						<CallUs
							pageName={pageName}
							number={siteSettings.phoneNumber}
							onClickProp={() =>
								handleClickTracking({
									event_name: 'navigation',
									click_type: 'tel',
								})
							}
						/>
					)}
					<div className="lg:hidden">
						<Hamburger
							label={headerContent?.sideNavigationOpenCopy}
							activeLabel={headerContent?.sideNavigationCloseCopy}
							labelVisibleMobile={false}
							isOpen={drawerRef === navRef}
							onClick={() => {
								setDrawerRef(navRef);
								handleClickTracking({
									event_name: 'navigation',
									click_action: 'open menu',
									click_type: 'icon',
									click_text: 'Menu',
								});
							}}
							className="relative focus:outline-0 focus:shadow-focus focus:rounded-xs"
						/>
					</div>
				</div>
			</section>
			<SideNav
				navRef={navRef}
				linkTitle={headerContent?.title}
				links={sideLinks}
				signInCopy={headerContent?.signInCopy}
			/>
		</header>
	);
};

HeaderCA.defaultProps = {
	siteTitle: 'Peninsula Homepage',
	isStorybook: false,
	mobileOnlyHamburger: false,
	homepageUrl: '/',
	siteSettings: {},
	locale: 'ca',
	gluLayout: false,
	headerContent: null,
	pageName: '',
};

HeaderCA.propTypes = {
	siteTitle: PropTypes.string,
	isStorybook: PropTypes.bool,
	headerContent: PropTypes.shape({
		primaryNavigation: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				link: PropTypes.string,
				treeChildren: PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						subText: PropTypes.string.isRequired,
						link: PropTypes.string.isRequired,
						icon: PropTypes.string.isRequired,
					})
				),
			})
		).isRequired,
		sideNavigation: PropTypes.oneOfType([
			PropTypes.arrayOf(
				PropTypes.shape({
					link: PropTypes.string.isRequired,
					title: PropTypes.string.isRequired,
				})
			),
			PropTypes.arrayOf(
				PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string.isRequired,
						link: PropTypes.string,
						treeChildren: PropTypes.arrayOf(
							PropTypes.shape({
								title: PropTypes.string.isRequired,
								subText: PropTypes.string.isRequired,
								link: PropTypes.string.isRequired,
								icon: PropTypes.string.isRequired,
							})
						),
					})
				),
				PropTypes.arrayOf(
					PropTypes.shape({
						link: PropTypes.string.isRequired,
						title: PropTypes.string.isRequired,
					})
				)
			),
		]),
		sideNavigationOpenCopy: PropTypes.string.isRequired,
		sideNavigationCloseCopy: PropTypes.string.isRequired,
		signInCopy: PropTypes.string,
	}),
	mobileOnlyHamburger: PropTypes.bool,
	homepageUrl: PropTypes.string,
	siteSettings: PropTypes.objectOf(PropTypes.string),
	locale: PropTypes.string,
	gluLayout: PropTypes.bool,
	pageName: PropTypes.string,
	disableCallUs: PropTypes.bool.isRequired,
};

export { HeaderCA };
