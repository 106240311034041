import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { Button } from '../../../../../atoms/button';
import { CallUs } from '../../../../../atoms/call-us';
import { SidebarHeader } from '../../../../../atoms/sidebar-header-v2-ca';
import { TextInput } from '../../../../../atoms/text-input-v2-ca';

import { useFormValidation } from '../../../../../../hooks/form-validation-modal-ca';
import { useSalesforceApiCA } from '../../../../../../hooks/salesforce-ca';
import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
} from '../../../../../../utils';

import { useSalesforceAzureApi } from '../../../../../../hooks/salesforce-azure-ca';

// Helper function to format phone number to E.164
const formatPhoneToE164 = (phoneNumber, countryCode = '1') => {
	const digits = phoneNumber.replace(/\D/g, '');
	if (phoneNumber.startsWith('+')) {
		return phoneNumber;
	}
	if (digits.startsWith(countryCode)) {
		return `+${digits}`;
	}
	return `+${countryCode}${digits}`;
};

const initialValidations = {
	firstName: { isValid: true },
	lastName: { isValid: true },
	companyName: { isValid: true },
	email: { isValid: true },
	phoneNumber: { isValid: true },
	callFrom: { isValid: true },
	callTo: { isValid: true },
	jobTitle: { isValid: true },
	postcode: { isValid: true },
	numberOfEmployees: { isValid: true },
};

const PricingFormCA = ({
	formTitle,
	setReference,
	customFormField,
	employeeCount,
}) => {
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const jobTitleRef = useRef(null);
	const postCodeRef = useRef(null);
	const noOfEmployeesRef = useRef(null);

	const [employees, setEmployees] = useState(employeeCount);

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforceCA } = useSalesforceApiCA();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const sideBarHeaderText =
		customFormField?.customFormTitle ||
		'Get a custom price for your business';
	const sideBarSubHeaderText =
		customFormField?.customFormSubtitle ||
		'Please leave your details below and one of our consultants will get back to you.';
	const submitButtonText =
		customFormField?.customFormButton || sideBarHeaderText;

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'pricing-quote-form',
			click_type: 'primary',
			click_title: formTitle,
			click_text: submitButtonText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: formTitle,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		if (!isValid) return;

		setSubmitting(true);

		// Format phone number to E.164
		const formattedPhone = formatPhoneToE164(formEntries.phoneNumber);

		// Single dataLayer push with all necessary data
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'ec_form_submit',
				user_data: {
					email: formEntries.email,
					phone: formattedPhone,
				},
			});
		}

		const payloadData = {
			firstName: formEntries.firstName,
			lastName: formEntries.lastName,
			company: formEntries.companyName,
			phoneNumber: formEntries.phoneNumber,
			email: formEntries.email,
			leadSource: 'Website',
			leadSourceDetails: 'Contact Us',
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
			jobTitle: formEntries.jobTitle,
			postcode: formEntries.postcode,
			numberOfEmployees: formEntries.numberOfEmployees,
			utmCampaign: getCookie('utm_campaign'),
			utmMedium: getCookie('utm_medium'),
			utmSource: getCookie('utm_source'),
			utmTerm: getCookie('utm_term'),
		};

		const response = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setReference(response.body.identifier);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.firstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.lastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.companyName.isValid && companyRef.current) {
			return companyRef.current.focus();
		}

		if (!validations.email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (
			!validations.numberOfEmployees.isValid &&
			noOfEmployeesRef.current
		) {
			return noOfEmployeesRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.jobTitle.isValid && jobTitleRef.current) {
			return jobTitleRef.current.focus();
		}
		if (!validations.postcode.isValid && postCodeRef.current) {
			return postCodeRef.current.focus();
		}
	}, [validations]);

	useEffect(() => {
		setEmployees(employeeCount);
	}, [employeeCount]);

	return isError ? (
		<>
			<div className="flex flex-col justify-between w-full max-w-sm mx-auto grow">
				<SidebarHeader
					heading="We've run into a technical error with your submission"
					text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
					ariaLive="assertive"
				/>
				<CallUs className="p-4 my-6" />
			</div>
		</>
	) : (
		<>
			<form
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full max-w-sm mx-auto grow"
			>
				<SidebarHeader
					heading={sideBarHeaderText}
					text={sideBarSubHeaderText}
				/>
				<div className="flex flex-col grow">
					<fieldset
						className="flex flex-col px-4 pt-6 bg-white grow"
						disabled={submitting}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>
						<TextInput
							labelText="First name"
							id="callbackFirstName"
							name="firstName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your first name"
							showValidation={!validations.firstName.isValid}
							ref={firstNameRef}
						/>

						<TextInput
							labelText="Last name"
							id="callbackLastName"
							name="lastName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your last name"
							showValidation={!validations.lastName.isValid}
							ref={lastNameRef}
						/>

						<TextInput
							labelText="Company"
							id="callbackCompany"
							name="companyName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your company name"
							showValidation={!validations.companyName.isValid}
							ref={companyRef}
						/>

						<TextInput
							labelText="Job title"
							id="callbackJobTitle"
							name="jobTitle"
							aria-required="false"
							className="w-full wrapper-small"
							validationMessage="Please enter your job title"
							showValidation={!validations.jobTitle.isValid}
							ref={jobTitleRef}
						/>

						<TextInput
							type="number"
							labelText="Number of employees"
							id="pricingNumberOfEmployees"
							name="numberOfEmployees"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter the number of employees within your company"
							showValidation={
								!validations.numberOfEmployees.isValid
							}
							ref={noOfEmployeesRef}
							value={employees !== 0 ? employees : ''}
							onChange={(e) => setEmployees(e.target.value)}
						/>

						<TextInput
							labelText="Email address"
							id="callbackEmail"
							name="email"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your email address"
							showValidation={!validations.email.isValid}
							ref={emailRef}
						/>

						<TextInput
							labelText="Phone number"
							type="tel"
							id="callbackPhoneNumber"
							name="phoneNumber"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter a phone number"
							showValidation={!validations.phoneNumber.isValid}
							ref={phoneNumberRef}
						/>

						<TextInput
							labelText="Postcode"
							id="pricingPostcode"
							name="postcode"
							aria-required="true"
							className="w-full wrapper-small max-w-[200px]"
							validationMessage="Please enter a postcode"
							showValidation={!validations.postcode.isValid}
							ref={postCodeRef}
							space={false}
						/>
					</fieldset>
				</div>
				<div className="max-w-md p-4 bg-white">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									submitting ? 'invisible' : null
								)}
							>
								{customFormField.customFormButton ||
									submitButtonText}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
						<div className="mt-4 mb-0 text-xs">
							View our privacy policy{' '}
							<a
								target="_self"
								href="/ca/privacy-policy"
								className="inline-flex items-center text-xs text-center text-blue-400 transition-colors hover:bg-brand-blue-400 focus:bg-brand-blue-500 focus:outline-0 focus:shadow-focus hover:bg-transparent focus:bg-transparent decoration-1 hover:text-brand-blue-400 focus:outline-focus"
							>
								here
							</a>
							.
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

PricingFormCA.defaultProps = {
	customFormField: '',
	employeeCount: 0,
};

PricingFormCA.propTypes = {
	setReference: PropTypes.func.isRequired,
	customFormField: PropTypes.node,
	employeeCount: PropTypes.number,
	formTitle: PropTypes.string.isRequired,
};

export { PricingFormCA };
