import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from '@reach/router';

import { MegaMenuTwoGroup } from '../../molecules/mega-menu-two-group';
import { MegaMenuOneGroup } from '../../molecules/mega-menu-one-group';
import { NavLink } from '../../atoms/nav-link';
import { Backdrop } from '../../atoms/backdrop-ca';
import { sendTrackingData } from '../../../utils/index';

import * as navStyles from './index.module.css';

export const NavDesktopVariants = {
	Default: navStyles.megalist,
	Unset: '',
};

const NavDesktop = ({
	links,
	drawerOpen,
	megaMenuVariant,
	isScrolled,
	scrollbarWidth,
	onScrollStateChange,
	...navAttributes
}) => {
	const linkRefs = useRef({});
	const megaRefs = useRef({});
	const location = useLocation();
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [hiddenMega, setHiddenMega] = useState(true);
	const [scrollDisabled, setScrollDisabled] = useState(false);

	const disableScroll = useCallback(() => {
		if (!scrollDisabled) {
			// Calculate the current scrollbar width
			const currentScrollbarWidth =
				window.innerWidth - document.documentElement.clientWidth;

			// Apply a container class to maintain width
			document.documentElement.classList.add('overflow-lock');

			// Store the original body padding
			const originalPadding =
				parseInt(
					window.getComputedStyle(document.body).paddingRight,
					10
				) || 0;

			// Apply styles in a single batch to minimize reflows
			requestAnimationFrame(() => {
				// Add padding to body equal to scrollbar width
				document.body.style.setProperty(
					'padding-right',
					`${originalPadding + currentScrollbarWidth}px`
				);
				document.body.style.setProperty('overflow', 'hidden');
			});

			setScrollDisabled(true);
			onScrollStateChange?.(true);
		}
	}, [scrollDisabled, onScrollStateChange]);

	const enableScroll = useCallback(() => {
		if (scrollDisabled) {
			requestAnimationFrame(() => {
				document.documentElement.classList.remove('overflow-lock');
				document.body.style.removeProperty('padding-right');
				document.body.style.removeProperty('overflow');
			});

			setScrollDisabled(false);
			onScrollStateChange?.(false);
		}
	}, [scrollDisabled, onScrollStateChange]);

	// Handle scroll restoration when drawer is open or when route changes
	useEffect(() => {
		if (drawerOpen) {
			setSelectedIndex(null);
			setHiddenMega(true);
			enableScroll();
		}
	}, [drawerOpen, enableScroll]);

	// Set CSS variable for scrollbar width and cleanup on unmount
	useEffect(() => {
		document.documentElement.style.setProperty(
			'--scrollbar-width',
			`${scrollbarWidth}px`
		);

		return () => {
			enableScroll();
			document.documentElement.style.removeProperty('--scrollbar-width');
		};
	}, [scrollbarWidth, enableScroll]);

	useEffect(
		() => () => {
			document.documentElement.classList.remove('overflow-lock');
			document.body.style.removeProperty('padding-right');
			document.body.style.removeProperty('overflow');
		},
		[location]
	);

	const toggleOpen = (index) => {
		if (selectedIndex === index) {
			setSelectedIndex(null);
			setHiddenMega(true);
			enableScroll();
		} else {
			setSelectedIndex(index);
			setHiddenMega(false);
			disableScroll();

			const megaRef = megaRefs.current[index];
			const linkRef = linkRefs.current[index];
			const siblingLinkRef = linkRefs.current[index + 1];

			const firstFocusableElement =
				megaRef.querySelectorAll('button, a')[0];
			const focusableContent = megaRef.querySelectorAll('button, a');
			const lastFocusableElement =
				focusableContent[focusableContent.length - 1];

			const handleKeydown = (e) => {
				if (e.key === 'Escape') {
					setSelectedIndex(null);
					setHiddenMega(true);
					linkRef.focus();
					enableScroll();
				}

				if (
					e.shiftKey &&
					document.activeElement === firstFocusableElement
				) {
					linkRef.focus();
					e.preventDefault();
				} else if (document.activeElement === lastFocusableElement) {
					siblingLinkRef?.focus();
					e.preventDefault();
				}
			};

			document.addEventListener('keydown', handleKeydown);
			firstFocusableElement?.focus();

			// Cleanup keydown listener
			return () => {
				document.removeEventListener('keydown', handleKeydown);
			};
		}
	};

	const handleClickTracking = (clickTitle) => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'navigation',
			click_action: 'open section',
			click_type: 'link',
			click_text: clickTitle,
			click_title: clickTitle,
		});
	};

	return (
		<nav {...navAttributes}>
			<ul className={clsx('flex', navStyles.navdesktop)}>
				{links.map((link, index) => {
					if (
						link.treeChildren &&
						link.treeChildren.length > 0 &&
						link.title === 'Who we serve'
					) {
						return (
							<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
								<NavLink
									className="relative"
									as="button"
									// eslint-disable-next-line no-return-assign
									ref={(ref) =>
										(linkRefs.current[index] = ref)
									}
									onClick={() => {
										toggleOpen(index);
										handleClickTracking(link.title);
									}}
									aria-expanded={selectedIndex === index}
									hasMegaMenu
								>
									{link.title}
								</NavLink>
								<MegaMenuOneGroup
									aria-label={`${link.title} menu`}
									hidden={
										selectedIndex !== index || hiddenMega
									}
									// eslint-disable-next-line no-return-assign
									ref={(ref) =>
										(megaRefs.current[index] = ref)
									}
									className={navStyles.megamenu}
									listClassName={clsx(
										megaMenuVariant === 'Unset'
											? null
											: NavDesktopVariants.Default
									)}
									links={link.treeChildren}
									backdrop={
										<Backdrop
											onClick={() => {
												setSelectedIndex(null);
												setHiddenMega(true);
												enableScroll();
											}}
										/>
									}
								/>
							</li>
						);
					}

					if (link.treeChildren && link.treeChildren.length > 0) {
						return (
							<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
								<NavLink
									className="relative"
									as="button"
									// eslint-disable-next-line no-return-assign
									ref={(ref) =>
										(linkRefs.current[index] = ref)
									}
									onClick={() => {
										toggleOpen(index);
										handleClickTracking(link.title);
									}}
									aria-expanded={selectedIndex === index}
									hasMegaMenu
								>
									{link.title}
								</NavLink>
								<MegaMenuTwoGroup
									aria-label={`${link.title} menu`}
									hidden={
										selectedIndex !== index || hiddenMega
									}
									// eslint-disable-next-line no-return-assign
									ref={(ref) =>
										(megaRefs.current[index] = ref)
									}
									className={navStyles.megamenu}
									listClassName={clsx(
										megaMenuVariant === 'Unset'
											? null
											: NavDesktopVariants.Default
									)}
									links={link.treeChildren}
									backdrop={
										<Backdrop
											onClick={() => {
												setSelectedIndex(null);
												setHiddenMega(true);
												enableScroll();
											}}
										/>
									}
								/>
							</li>
						);
					}

					return (
						<li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
							<NavLink
								// eslint-disable-next-line no-return-assign
								ref={(ref) => (linkRefs.current[index] = ref)}
								to={link.link}
								onClick={() => handleClickTracking(link.title)}
							>
								{link.title}
							</NavLink>
						</li>
					);
				})}
			</ul>
		</nav>
	);
};

NavDesktop.defaultProps = {
	megaMenuVariant: 'Default',
	isScrolled: false,
	scrollbarWidth: 0,
	onScrollStateChange: () => {},
};

NavDesktop.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			link: PropTypes.string,
			treeChildren: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					subText: PropTypes.string.isRequired,
					link: PropTypes.string.isRequired,
					icon: PropTypes.string.isRequired,
				})
			),
		})
	).isRequired,
	drawerOpen: PropTypes.bool.isRequired,
	megaMenuVariant: PropTypes.oneOf(['Default', 'Unset']),
	isScrolled: PropTypes.bool,
	scrollbarWidth: PropTypes.number,
	onScrollStateChange: PropTypes.func,
};

export { NavDesktop };
