import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from '@reach/router';
import { Button } from '../../../../../atoms/button';
import { CallUs } from '../../../../../atoms/call-us';
import { SidebarHeader } from '../../../../../atoms/sidebar-header-short';
import { TextInput } from '../../../../../atoms/text-input-v2-ca';

import { useFormValidation } from '../../../../../../hooks/form-validation-modal-short-ca';
import { useSalesforceApiCA } from '../../../../../../hooks/salesforce-ca';
import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
} from '../../../../../../utils';

import { useSalesforceAzureApi } from '../../../../../../hooks/salesforce-azure-ca';

// Helper function to format phone number to E.164
const formatPhoneToE164 = (phoneNumber, countryCode = '1') => {
	const digits = phoneNumber.replace(/\D/g, '');
	if (phoneNumber.startsWith('+')) {
		return phoneNumber;
	}
	if (digits.startsWith(countryCode)) {
		return `+${digits}`;
	}
	return `+${countryCode}${digits}`;
};

const initialValidations = {
	fullName: { isValid: true },
	companyName: { isValid: true },
	email: { isValid: true },
	phoneNumber: { isValid: true },
	callFrom: { isValid: true },
	callTo: { isValid: true },
	title: { isValid: true },
};

const AdviceShortFormCA = ({ setReference, customFormField }) => {
	const fullNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const titleRef = useRef(null);
	const phoneNumberRef = useRef(null);
	const [isError, setIsError] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [title, setTitle] = useState('');

	const { validations, validateForm } = useFormValidation(initialValidations);
	const { fetchSalesforceCA } = useSalesforceApiCA();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const sideBarHeaderText =
		customFormField?.customFormTitle || 'Schedule a FREE advice call';
	const sideBarSubHeaderText =
		customFormField?.customFormSubtitle ||
		'Need immediate help with an issue? Let us know how to get in touch with you.';
	const submitButtonText =
		customFormField?.customFormButton || 'Schedule a FREE advice call';

	const { fetchSalesforceAzure } = useSalesforceAzureApi();

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('click', {
			event_name: 'component_interaction',
			component_name: 'advice-form',
			click_type: 'primary',
			click_title: sideBarHeaderText,
			click_text: submitButtonText,
		});
	};

	const handleCallbackTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		sendTrackingData('arrange_a_callback', {
			form_name: sideBarHeaderText,
		});
	};
	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);
	// Create an event handler so you can call the verification on button click event or form submit
	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);
		if (!isValid) return;

		setSubmitting(true);

		// Format phone number to E.164
		const formattedPhone = formatPhoneToE164(formEntries.phoneNumber);

		// Single dataLayer push with all necessary data
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'ec_form_submit',
				user_data: {
					email: formEntries.email,
					phone: formattedPhone,
				},
			});
		}

		// Splitting full name into firstName and lastName
		const fullName = formEntries.fullName.trim();
		const nameParts = fullName.split(/\s+/);

		let firstNameData = '';
		let lastNameData = 'N/A';

		if (nameParts.length === 1) {
			// If there's only one name
			[firstNameData] = nameParts;
		} else {
			// If there are multiple names
			lastNameData = nameParts.pop(); // Get the last name
			firstNameData = nameParts.join(' '); // Join the remaining parts as first name
		}

		const payloadData = {
			jobTitle: title,
			firstName: firstNameData,
			lastName: lastNameData,
			company: formEntries.companyName,
			phoneNumber: formEntries.phoneNumber,
			email: formEntries.email,
			leadSource: 'Website',
			leadSourceDetails: 'Contact Us',
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
			utmCampaign: getCookie('utm_campaign'),
			utmMedium: getCookie('utm_medium'),
			utmSource: getCookie('utm_source'),
			utmTerm: getCookie('utm_term'),
		};

		const response = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setReference(response.body.identifier);
				handleCallbackTracking();
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	useEffect(() => {
		if (!validations.title?.isValid && titleRef.current) {
			return titleRef.current.focus();
		}
		if (!validations.fullName.isValid && fullNameRef.current) {
			return fullNameRef.current.focus();
		}

		if (!validations.companyName.isValid && companyRef.current) {
			return companyRef.current.focus();
		}

		if (!validations.email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (!validations.phoneNumber.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}
	}, [validations]);

	return isError ? (
		<>
			<div className="flex flex-col justify-between w-full max-w-sm mx-auto grow">
				<SidebarHeader
					heading="We've run into a technical error with your submission"
					text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
					ariaLive="assertive"
				/>
				<CallUs className="p-4" />
			</div>
		</>
	) : (
		<>
			<form
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full max-w-sm mx-auto grow"
			>
				<SidebarHeader
					heading={sideBarHeaderText}
					text={sideBarSubHeaderText}
				/>
				<div className="flex flex-col grow">
					<fieldset
						className="flex flex-col px-4 pt-3 bg-white grow"
						disabled={submitting}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>

						<div className="flex flex-col w-full mb-3 whitespace-nowrap text-md">
							<div className="mr-3 w-fit-content text-md font-centra-medium">
								I am:
							</div>
							<div className="mt-2 text-md font-centra-regular xl:mt-1">
								<div className="flex flex-row flex-wrap gap-4 xl:justify-start xl:gap-1">
									{/* <div className="flex flex-wrap justify-start gap-3"> */}
									<div className="flex flex-row">
										<div className="mt-1">
											<input
												onChange={(e) => {
													setTitle(
														e.currentTarget.value
													);
												}}
												type="radio"
												value="an employer"
												ref={titleRef}
												name="Title"
												id="title-radio-group-1"
												className="w-4 h-4 border-2 border-blue-500 rounded-full"
											/>
										</div>
										<div className="xl:whitespace-normal w-[83px] text-sm ml-2 xl:leading-4">
											<label
												htmlFor="title-radio-group-1"
												className=""
											>
												an employer
											</label>
										</div>
									</div>
									<div className="flex flex-row">
										<div className="mt-1">
											<input
												onChange={(e) => {
													setTitle(
														e.currentTarget.value
													);
												}}
												type="radio"
												value="business representative"
												name="Title"
												ref={titleRef}
												id="title-radio-group-3"
												className="w-4 h-4 border-2 border-blue-500 rounded-full"
											/>
										</div>
										<div className="xl:whitespace-normal w-[83px] text-sm ml-2 xl:leading-4">
											<label
												htmlFor="title-radio-group-3"
												className=""
											>
												business representative
											</label>
										</div>
									</div>
									<div className="flex flex-row xl:ml-8">
										<div className="mt-1">
											<input
												onChange={(e) => {
													setTitle(
														e.currentTarget.value
													);
												}}
												type="radio"
												value="an employee"
												name="Title"
												ref={titleRef}
												id="title-radio-group-2"
												className="w-4 h-4 border-2 border-blue-500 rounded-full"
											/>
										</div>
										<div className="xl:whitespace-normal w-[83px] xl:w-[70px] text-sm ml-2 xl:leading-4">
											<label
												htmlFor="title-radio-group-2"
												className=""
											>
												an employee
											</label>
										</div>
									</div>
									{!validations.title?.isValid && (
										<p className="mt-4 text-left text-alert-500 status">
											Please select an option.
										</p>
									)}
								</div>
							</div>
						</div>

						<TextInput
							labelText="Full name"
							id="callbackFullName"
							name="fullName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your full name"
							showValidation={!validations.fullName.isValid}
							ref={fullNameRef}
						/>

						<TextInput
							labelText="Company"
							id="callbackCompany"
							name="companyName"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your company name"
							showValidation={!validations.companyName.isValid}
							ref={companyRef}
						/>

						<TextInput
							labelText="Email address"
							id="callbackEmail"
							name="email"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter your email address"
							showValidation={!validations.email.isValid}
							ref={emailRef}
						/>

						<TextInput
							labelText="Phone number"
							type="tel"
							id="callbackPhoneNumber"
							name="phoneNumber"
							aria-required="true"
							className="w-full wrapper-small"
							validationMessage="Please enter a phone number"
							showValidation={!validations.phoneNumber.isValid}
							ref={phoneNumberRef}
						/>
					</fieldset>
				</div>
				<div className="max-w-md p-4 bg-white">
					<div className="wrapper-small">
						<Button
							id="adviceShortFormSubmitBtn"
							type="submit"
							className={clsx(
								'adviceShortFormCASubmitBtn',
								submitting && 'justify-center loading'
							)}
						>
							<span
								className={clsx(
									submitting ? 'invisible' : null
								)}
							>
								{submitButtonText || 'Talk to us today'}
							</span>
							{submitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
						<div className="mt-4 mb-0 text-xs">
							View our privacy policy{' '}
							<a
								target="_self"
								href="/ca/privacy-policy"
								className="inline-flex items-center text-xs text-center text-blue-400 transition-colors hover:bg-brand-blue-400 focus:bg-brand-blue-500 focus:outline-0 focus:shadow-focus hover:bg-transparent focus:bg-transparent decoration-1 hover:text-brand-blue-400 focus:outline-focus"
							>
								here
							</a>
							.
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

AdviceShortFormCA.defaultProps = {
	customFormField: '',
};

AdviceShortFormCA.propTypes = {
	setReference: PropTypes.func.isRequired,
	customFormField: PropTypes.node,
};

export { AdviceShortFormCA };
